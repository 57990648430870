import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./styles.css";
import AppBar from '../../Shared/AppBar';
import NavBar from '../../Shared/NavBar';
import Main from './Main';
import Feedback from './Feedback';
import axios from 'axios';
import "./styles.css";

class FAQ extends Component {
    constructor(props) {
        super(props);
        window.location.pathname.includes('/fr') ?
        this.state = {
            page: 'Questions et contact',
            back: false,
            value: 'help',
            comment: '',
            contactType: null,
            email: '',
            phone: '',
            userProfile: []
          }
        : window.location.pathname.includes('/it') ?
        this.state = {
            page: 'Domande e contatto',
            back: false,
            value: 'help',
            comment: '',
            contactType: null,
            email: '',
            phone: '',
            userProfile: []
          }
        :
        this.state = {
          page: 'Fragen und Kontakt',
          back: false,
          value: 'help',
          comment: '',
          contactType: null,
          email: '',
          phone: '',
          userProfile: []
        }
      }

    componentDidMount() {
        this.isUserAuthenticated();
        this.handleUser();
        this.visitsChecker();
    }

    visitsChecker = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Get'
    
        axios.get(proxyUrl + targetUrl, {
            headers: { "Authorization": window.localStorage.AccessToken }
        })
        .then((response) => {
            this.setState({ visits: response.data });
            console.log('VISITS RESPONSE: ', response.data);
            this.visitsHandler();
        })
        .catch((error) => { console.log(error);
        })
      }

      visitsHandler = () => {
        if (this.state.visits.IsQuestionPageVisited === true) {
          console.log('Welcome Back');
        } else {
          var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
              targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Post?page=QuestionPage'
          

          var myHeaders = new Headers();
              myHeaders.append("Authorization", window.localStorage.AccessToken);
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow'
          };

          fetch(proxyUrl + targetUrl, requestOptions)
          .then(response => response.json())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
        }
      }

    loadingScreen = () => {
        this.setState({
            loading: true
        })
    }

    handleUser = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/authentication/userInfo'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
                userProfile: result.Preload,
            });
        })
        .catch(error => console.log('error', error));
    }
    
    isUserAuthenticated = () => {
        var auth = localStorage.AccessToken
        if (auth === undefined) {
            localStorage.clear();
            this.props.history.push('/')
        }
    }

    backButtonHandler = () => {
        if (this.state.page === 'Fragen und Kontakt' && this.state.contactType === null) {
          this.props.history.push('home')
        } else {
            this.setState({
                contactType: null,
                back: false
            })
        }
    }

    backTrueHandler = () => {
        this.setState({
            back: true
        })
    }

    feedbackHandler = (e) => {
        this.setState({
            contactType: e
        })
    }

    onChangeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    technicalIssue = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Contacts/TechnicalIssue'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify({
            "Comment": this.state.comment,
            "ContactType": this.state.contactType,
            "EMail": this.state.email,
            "Firstname": this.state.userProfile.Firstname,
            "Lastname": this.state.userProfile.Name,
            "Sex": this.state.userProfile.Sex,
            "PhoneNumber": this.state.phone
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.text())
        .then(this.loadingScreen)
        .then(
            setTimeout(()=>{
                this.props.history.push('/');
            }, 1500)
        )
        .catch(error => console.log('error', error));
    }

    render() {
        // console.log('FAQ PAGE STATE: ', this.state);
        return(
            <React.Fragment>
                <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
                {
                    this.state.contactType === '1' ?
                        <Feedback state={this.state} backTrueHandler={this.backTrueHandler} onChangeHandler={this.onChangeHandler} technicalIssue={this.technicalIssue} />
                    : this.state.contactType === '2' ?
                        <Feedback state={this.state} backTrueHandler={this.backTrueHandler} onChangeHandler={this.onChangeHandler} technicalIssue={this.technicalIssue} />
                    : <Main feedbackHandler={this.feedbackHandler} />
                }
                <NavBar state={this.state} />
            </React.Fragment>
        )
    }
}

export default withRouter(FAQ);