import React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import Main from './containers/Main';
import Register from './containers/Register';
import ForgotPin from './containers/ForgotPin';
import Welcome from './containers/Welcome';
import Home from './containers/Home';
import Information from "./components/Information";
import FAQ from "./components/FAQ";
import Settings from "./components/Settings";
import ThankYou from "./components/ThankYou";
import Statistics from "./components/Statistics";
import Unsubscribe from "./components/Unsubscribe";
import Landing from "./components/LandingPage";

export default () => 
    <BrowserRouter>
        <Switch>
            <Route path="/" exact component={Main} />
            <Route path="/de" exact component={Main} />
            <Route path="/de/register" exact component={Register} />
            <Route path="/de/forgot/pin" exact component={ForgotPin} />
            <Route path="/de/welcome" exact component={Welcome} />
            <Route path="/de/home" exact component={Home} />
            <Route path="/de/information" exact component={Information} />
            <Route path="/de/help" exact component={FAQ} />
            <Route path="/de/settings" exact component={Settings} />
            <Route path="/de/vielendank" exact component={ThankYou} />
            <Route path="/de/statistics" exact component={Statistics} />
            <Route path="/de/u" exact component={Unsubscribe} />
            <Route path="/de/landing" exact component={Landing} />
            <Route path="/fr" exact component={Main} />
            <Route path="/fr/register" exact component={Register} />
            <Route path="/fr/forgot/pin" exact component={ForgotPin} />
            <Route path="/fr/welcome" exact component={Welcome} />
            <Route path="/fr/home" exact component={Home} />
            <Route path="/fr/information" exact component={Information} />
            <Route path="/fr/help" exact component={FAQ} />
            <Route path="/fr/settings" exact component={Settings} />
            <Route path="/fr/vielendank" exact component={ThankYou} />
            <Route path="/fr/statistics" exact component={Statistics} />
            <Route path="/fr/u" exact component={Unsubscribe} />
            <Route path="/fr/landing" exact component={Landing} />
            <Route path="/it" exact component={Main} />
            <Route path="/it/register" exact component={Register} />
            <Route path="/it/forgot/pin" exact component={ForgotPin} />
            <Route path="/it/welcome" exact component={Welcome} />
            <Route path="/it/home" exact component={Home} />
            <Route path="/it/information" exact component={Information} />
            <Route path="/it/help" exact component={FAQ} />
            <Route path="/it/settings" exact component={Settings} />
            <Route path="/it/vielendank" exact component={ThankYou} />
            <Route path="/it/statistics" exact component={Statistics} />
            <Route path="/it/u" exact component={Unsubscribe} />
            <Route path="/it/landing" exact component={Landing} />
            <Redirect from='/register' to='/de/register' />
            <Redirect from='/forgot/pin' to='/de/forgot/pin' />
            <Redirect from='/welcome' to='/de/welcome' />
            <Redirect from='/home' to='/de/home' />
            <Redirect from='/information' to='/de/information' />
            <Redirect from='/help' to='/de/help' />
            <Redirect from='/settings' to='/de/settings' />
            <Redirect from='/vielendank' to='/de/vielendank' />
            <Redirect from='/statistics' to='/de/statistics' />
            <Redirect from='/u' to='/de/u' />
            <Redirect from='/landing' to='/de/landing' />
            <Redirect from='*' to='/' />
        </Switch>
    </BrowserRouter>
