import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { DatePicker } from "@material-ui/pickers";
import TextField from '@material-ui/core/TextField';
import train from '../../../assets/icons/train.svg';
import tram from '../../../assets/icons/tram.svg';
import bus from '../../../assets/icons/bus.svg';
import boat from '../../../assets/icons/boat.svg';
import andere from '../../../assets/icons/andere.svg';
import RemoveCircleSharpIcon from '@material-ui/icons/RemoveCircleSharp';
import AddCircleSharpIcon from '@material-ui/icons/AddCircleSharp';
import axios from 'axios';
import InputAdornment from '@material-ui/core/InputAdornment';
import * as moment from 'moment';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { makeStyles } from '@material-ui/core';
import 'moment/locale/de';
import "./styles.css";

export const useStyles = makeStyles({
	toolbar: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	}
});

// eslint-disable-next-line
const CustomToolbar = function (props) {

	const { date,
		isLandscape,
		openView,
		setOpenView,
		title} = props;

	const handleChangeViewClick = (view) => (e) => {

		setOpenView(view);

	}

	const classes = useStyles();

	return (
		<PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
			<ToolbarButton
				onClick={handleChangeViewClick("year")}
				variant="h6"
				label={date.format("YYYY")}
				selected={openView === "year"}
			/>
			<ToolbarButton
				onClick={handleChangeViewClick("date")}
				variant="h4"
				selected={openView === "date"}
				label={date.format("dddd, D. MMM")}
			/>
		</PickerToolbar>
	);

}

class Main extends Component {
    constructor(props) {
    super(props);
    window.location.pathname.includes('/fr') ?
    this.state = {
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: '',
      toBahn: '',
      toTram: '',
      toBus: '',
      toSchiff: '',
      toAndere: '',
      stations: [],
      viaStations: [],
      endStations: [],
      dialogError: 'Veuillez sélectionner le moyen de transport utilisé',
      fade: 'out',
      dialog: 'firstTimeOut'
    }
    : window.location.pathname.includes('/fr') ?
    this.state = {
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: '',
      toBahn: '',
      toTram: '',
      toBus: '',
      toSchiff: '',
      toAndere: '',
      stations: [],
      viaStations: [],
      endStations: [],
      dialogError: 'Selezioni il mezzo di trasporto utilizzato',
      fade: 'out',
      dialog: 'firstTimeOut'
    }
    :
    this.state = {
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: '',
      toBahn: '',
      toTram: '',
      toBus: '',
      toSchiff: '',
      toAndere: '',
      stations: [],
      viaStations: [],
      endStations: [],
      dialogError: 'Bitte wählen Sie das benutzte Transportmittel aus',
      fade: 'out',
      dialog: 'firstTimeOut'
    }

    this.searchHandler = this.searchHandler.bind(this);
    this.endSearchHandler = this.endSearchHandler.bind(this);
    this.viaSearchHandler = this.viaSearchHandler.bind(this);
    this.onChangeDoubleFunVia = this.onChangeDoubleFunVia.bind(this);
  }

  componentDidMount() {
    this.currentStationHandler();
    this.extraStationsTravelLoad();
    this.extraStopNames();
  }

  extraStopNames = () => {
    this.props.state.ExtraStops.map( (via, index) => (
      this.setState({
        [index + 'Name']: via.ViaStationName
      })
    ))
  }

  errorDialog = () => {
    window.scrollTo(0, 0);
    this.setState({
      fade: 'in'
    });
    setTimeout(()=>{
      this.setState({
        fade: 'out'
      });
    }, 2500);
  }

  errorChecker = () => {
    this.props.state.ExtraStops.map( via => (
      via.MeansOfTravel === undefined ?
        this.errorDialog()
      : null
    ))
    
    setTimeout(()=>{
      if (this.state.fade === 'out') {
        this.props.putJourney()
      }
    }, 500);
  }

  onChangeDoubleFun = (e) => {
    this.searchHandler(e);
    this.props.onChangeHandler(e);
  }

  onChangeDoubleFunVia = (e) => {
    this.setState({
      [e.target.name + 'Name']: e.target.value
    })
    this.viaSearchHandler(e);
    this.props.onChangeHandler(e);
  }

  onChangeDoubleFunEnd = (e) => {
    this.endSearchHandler(e);
    this.props.onChangeHandler(e);
  }

  searchHandler(event){
    axios.get('https://s25-admin.allianceswisspass.dev.mxm.ch/de/api/stations', {
          params: { query: ' ' + event.target.value}
    })
    .then((response) => {
        this.setState({ stations: response.data });
    })
    .catch((error) => { console.log(error);
    })
  }

  viaSearchHandler(event){
    event.persist();
    axios.get('https://s25-admin.allianceswisspass.dev.mxm.ch/de/api/stations', {
          params: { query: ' ' + event.target.value}
    })
    .then((response) => {
        this.setState(
          { 
            [event.target.name]: response.data 
          }
        );
    })
    .catch((error) => { console.log(error);
    })
  }

  endSearchHandler(event){
    axios.get('https://s25-admin.allianceswisspass.dev.mxm.ch/de/api/stations', {
          params: { query: ' ' + event.target.value}
    })
    .then((response) => {
        this.setState({ endStations: response.data });
    })
    .catch((error) => { console.log(error);
    })
  }

  resetStartSearchResultsHandler = (n, c) => {
        this.setState({
          stations: []
        })
        this.props.handleStartPointUpdate(n, c);
  }

  resetViaSearchResultsHandler = (index, id, c, nc, nn, mt) => {
    this.setState({
        [c]: [],
        [c + 'Name']: nn
    })
    //this.props.handleStartPointUpdate(n, c);
    //this.props.removeConnectingPoint(n);
    this.props.handleViaStationUpdate(index, id, nc, nn, mt);
}

  resetEndSearchResultsHandler = (n, c) => {
        this.setState({
            endStations: []
        })
        this.props.handleEndPointUpdate(n, c);
  }

  currentStationHandler = () => {
    if (this.props.state.startTravel === 'Bahn') {
        this.setState({
            fromBahn: 'active'
        })
    }
    if (this.props.state.startTravel === 'Tram') {
        this.setState({
            fromTram: 'active'
        })
    }
    if (this.props.state.startTravel === 'Bus') {
        this.setState({
            fromBus: 'active'
        })
    }
    if (this.props.state.startTravel === 'Schiff') {
        this.setState({
            fromSchiff: 'active'
        })
    }
    if (this.props.state.startTravel === 'Andere') {
        this.setState({
            fromAndere: 'active'
        })
    }
  }

  extraStationsTravelLoad = () => {
    this.props.state.ExtraStops.map( viaStation => (
      this.setState({
        [viaStation.ViaId]: viaStation.MeansOfTravel
      })
    ))
  }

  fromBahnHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: 'active',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: ''
    })
  }

  fromTramHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: '',
      fromTram: 'active',
      fromBus: '',
      fromSchiff: '',
      fromAndere: ''
    })
  }

  fromBusHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: '',
      fromTram: '',
      fromBus: 'active',
      fromSchiff: '',
      fromAndere: ''
    })
  }

  fromSchiffHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: 'active',
      fromAndere: ''
    })
  }

  fromAndereHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: 'active'
    })
  }

  viaBahn = (index, id, code, name) => {
    // console.log('Via Bahn Name: ', name);
    
    this.props.handleViaStationTransportTypeUpdate(id, code, name, 'Bahn')
    setTimeout(()=>{
      this.setState({
        [id]: 'Bahn',
        [code + 'Name']: name
      })
    }, 20)
  }

  viaTram = (index, id, code, name) => {
    this.props.handleViaStationTransportTypeUpdate(id, code, name, 'Tram')
    setTimeout(()=>{
      this.setState({
        [id]: 'Tram',
        [code + 'Name']: name
      })
    }, 20)
  }

  viaBus = (index, id, code, name) => {
    this.props.handleViaStationTransportTypeUpdate(id, code, name, 'Bus')
    setTimeout(()=>{
      this.setState({
        [id]: 'Bus',
        [code + 'Name']: name
      })
    }, 20)
  }

  viaSchiff = (index, id, code, name) => {
    this.props.handleViaStationTransportTypeUpdate(id, code, name, 'Schiff')
    setTimeout(()=>{
      this.setState({
        [id]: 'Schiff',
        [code + 'Name']: name
      })
    }, 20)
  }

  viaAndere = (index, id, code, name) => {
    this.props.handleViaStationTransportTypeUpdate(id, code, name, 'Andere')
    setTimeout(()=>{
      this.setState({
        [id]: 'Andere',
        [code + 'Name']: name
      })
    }, 20)
  }

  render() {
      // console.log('UPDATE PROPS: ', this.props.state);
      // console.log('UPDATE STATE: ', this.state);
      // eslint-disable-next-line no-lone-blocks
      {
        window.location.pathname.includes('/fr') ?
          moment.locale('fr')
        : window.location.pathname.includes('/it') ?
          moment.locale('it')
        :
          moment.locale('de')
      }
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className="datumScreen" disableGutters="true">
            <Container maxWidth="false" disableGutters="true" className="homeBanner">
                <Typography variant="h6" className="homeBannerText">
                  Bitte bearbeite deine Reise und klicke abschliessend auf "Reise aktualisieren"
                </Typography>
            </Container>
            <Card raised="true" className={"errorDialog transportDialog fade-" + this.state.fade}>
              <CardContent className="errorContent">
                <Typography align="center">
                  {this.state.dialogError}
                </Typography>
              </CardContent>
            </Card>
            <Container>
                <Button variant="contained" className="selectedDateButton" disableElevation size="large" fullWidth>
                    <DatePicker
                        autoOk="false"
                        label="Clearable"
                        inputVariant="filled"
                        okLabel={window.location.pathname.includes('/fr') ?  'Continuer' : window.location.pathname.includes('/it') ? 'Avanti' : 'Weiter'}
                        cancelLabel={window.location.pathname.includes('/fr') ?  'Interrompre' : window.location.pathname.includes('/it') ? 'Annullare' : 'Abbrechen'}
                        TextFieldComponent={'button'}
                        minDate={new Date(this.props.state.userProfile.PeriodStart)}
                        maxDate={new Date(this.props.state.userProfile.PeriodEnd)}
                        value={this.props.state.date}
                        onChange={this.props.handleDateUpdate}
                        disableToolbar
                    />
                    {moment(this.props.state.date).format('dddd, D. MMM. YYYY')}
                </Button>

                <TextField autoComplete='off' onChange={this.onChangeDoubleFun} name="startPointName" className="updateFields" fullWidth id="outlined-helperText" label={window.location.pathname.includes('/fr') ?  'Lieu de départ' : window.location.pathname.includes('/it') ? 'Luogo di partenza' : 'Startort'} value={this.props.state.startPointName} variant="outlined" InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AddCircleSharpIcon onClick={() => this.props.handleNewViaStationUpdate(0, -1)} className="addViaStationUJ" />
                                </InputAdornment>
                              ),
                            }} />
                <ul className="searchResults">
                    {
                        this.state.stations.map( stations => (
                            <li onClick={() => this.resetStartSearchResultsHandler(stations.name, stations.code)}>{stations.name}</li>
                        ))
                    }
                </ul>
                <Typography className="transportationType">
                    <img onClick={() => this.fromBahnHandler('Bahn')} className={"transportTypeOverlay" + this.state.fromBahn} src={train} alt="Train" />
                    <img onClick={() => this.fromTramHandler('Tram')} className={"transportTypeOverlay" + this.state.fromTram} src={tram} alt="Tram" />
                    <img onClick={() => this.fromBusHandler('Bus')} className={"transportTypeOverlay" + this.state.fromBus} src={bus} alt="Bus" />
                    <img onClick={() => this.fromSchiffHandler('Schiff')} className={"transportTypeOverlay" + this.state.fromSchiff} src={boat} alt="Boat" />
                    <img onClick={() => this.fromAndereHandler('Andere')} className={"transportTypeOverlay" + this.state.fromAndere} src={andere} alt="andere" />
                </Typography>
                {
                  this.props.state.ExtraStops.length > 0 ?
                  <div>
                    {
                      this.props.state.ExtraStops.map( (viaStation, index) => (
                        <div>
                          <TextField
                            autoComplete='off'
                            // disabled={this.state[viaStation.ViaId] === undefined ? true : false}
                            onChange={this.onChangeDoubleFunVia} 
                            name={viaStation.ViaStationId}
                            className="updateFields" 
                            fullWidth
                            value={this.state[viaStation.ViaStationId + 'Name']}
                            label={viaStation.ViaStationName}
                            placeholder={this.state[viaStation.ViaStationName + 'Name']}
                            variant="outlined"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AddCircleSharpIcon onClick={() => this.props.handleNewViaStationUpdate(index + 1, -1)} className="addViaStationUJ" />
                                  <RemoveCircleSharpIcon onClick={() => this.props.removeConnectingPoint(viaStation.ViaStationName)} className="removeViaStationUJ" />
                                </InputAdornment>
                              ),
                            }} />
                          <ul className="searchResults">
                            {
                              this.state[viaStation.ViaStationId] === undefined ?
                                null
                              : this.state[viaStation.ViaStationId].map( stations => (
                                  <li onClick={() => this.resetViaSearchResultsHandler(index, viaStation.ViaId, viaStation.ViaStationId, stations.code, stations.name, this.state[viaStation.ViaId])}>{stations.name}</li>
                                ))
                              
                            }
                          </ul>
                        {
                          viaStation.MeansOfTravel === 'Bahn' ?
                          <Typography className="transportationType"> 
                            <img onClick={() => this.viaBahn(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlayActive"} src={train} alt="Train" />
                            <img onClick={() => this.viaTram(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={tram} alt="Tram" />
                            <img onClick={() => this.viaBus(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={bus} alt="Bus" />
                            <img onClick={() => this.viaSchiff(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={boat} alt="Boat" />
                            <img onClick={() => this.viaAndere(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={andere} alt="andere" />
                          </Typography>
                          : viaStation.MeansOfTravel === 'Tram' ?
                          <Typography className="transportationType"> 
                            <img onClick={() => this.viaBahn(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={train} alt="Train" />
                            <img onClick={() => this.viaTram(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlayActive"} src={tram} alt="Tram" />
                            <img onClick={() => this.viaBus(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={bus} alt="Bus" />
                            <img onClick={() => this.viaSchiff(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={boat} alt="Boat" />
                            <img onClick={() => this.viaAndere(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={andere} alt="andere" />
                          </Typography>
                          : viaStation.MeansOfTravel === 'Bus' ?
                          <Typography className="transportationType"> 
                            <img onClick={() => this.viaBahn(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={train} alt="Train" />
                            <img onClick={() => this.viaTram(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={tram} alt="Tram" />
                            <img onClick={() => this.viaBus(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlayActive"} src={bus} alt="Bus" />
                            <img onClick={() => this.viaSchiff(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={boat} alt="Boat" />
                            <img onClick={() => this.viaAndere(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={andere} alt="andere" />
                          </Typography>
                          : viaStation.MeansOfTravel === 'Schiff' ?
                          <Typography className="transportationType"> 
                            <img onClick={() => this.viaBahn(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={train} alt="Train" />
                            <img onClick={() => this.viaTram(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={tram} alt="Tram" />
                            <img onClick={() => this.viaBus(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={bus} alt="Bus" />
                            <img onClick={() => this.viaSchiff(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlayActive"} src={boat} alt="Boat" />
                            <img onClick={() => this.viaAndere(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={andere} alt="andere" />
                          </Typography>
                          : viaStation.MeansOfTravel === 'Andere' ?
                          <Typography className="transportationType"> 
                            <img onClick={() => this.viaBahn(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={train} alt="Train" />
                            <img onClick={() => this.viaTram(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={tram} alt="Tram" />
                            <img onClick={() => this.viaBus(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={bus} alt="Bus" />
                            <img onClick={() => this.viaSchiff(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={boat} alt="Boat" />
                            <img onClick={() => this.viaAndere(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlayActive"} src={andere} alt="andere" />
                          </Typography>
                          : <Typography className="transportationType"> 
                              <img onClick={() => this.viaBahn(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={train} alt="Train" />
                              <img onClick={() => this.viaTram(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={tram} alt="Tram" />
                              <img onClick={() => this.viaBus(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={bus} alt="Bus" />
                              <img onClick={() => this.viaSchiff(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={boat} alt="Boat" />
                              <img onClick={() => this.viaAndere(index, viaStation.ViaId, viaStation.ViaStationId, this.state[index + 'Name'])} className={"transportTypeOverlay"} src={andere} alt="andere" />
                            </Typography>
                        }
                        </div>
                      ))
                    }
                  </div>
                  : null
                }
                <TextField autoComplete='off' onChange={this.onChangeDoubleFunEnd} name="endPointName" className="updateFields" fullWidth id="outlined-helperText" label={window.location.pathname.includes('/fr') ?  'Lieu d\'arrivée' : window.location.pathname.includes('/it') ? 'Destinazione' : 'Zielort'} value={this.props.state.endPointName} variant="outlined" />
                <ul className="searchResults">
                    {
                        this.state.endStations.map( stations => (
                            <li onClick={() => this.resetEndSearchResultsHandler(stations.name, stations.code)}>{stations.name}</li>
                        ))
                    }
                </ul>
            </Container>
            <Container className="updateButtonSection">
                <Button onClick={() => this.errorChecker()} className="updateButton" disableElevation variant="contained">Reise Aktualisieren</Button>
            </Container>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);