import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./styles.css";
import AppBar from '../../Shared/AppBar';
import NavBar from '../../Shared/NavBar';
import MainHomeScreen from './Main';
import DatumScreen from './Datum';
import StartortScreen from './Startort';
import ZielortScreen from './Zielort';
import UmsteigeorteScreen from './Umsteigeorte';
import UpdateJourney from './UpdateJourney';
import FinishJourney from './FinishJourney';
import './styles.css';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
          page: 'Reisejournal',
          back: true,
          prev: '',
          value: 'home',
          loading: false,
          date: null,
          startPointName: null,
          startPointCode: null,
          initExtraStops: [],
          toSubmitStops: [],
          ExtraStops: [],
          endPointName: null,
          endPointCode: null,
          connectingPointScreen: null,
          userProfile: [],
          startTravel: null,
          endTravel: null,
          editTripId : null,
          tripsData: [],
          connectingPointIndex: 0,
          path: window.location.pathname.substring(0,3)
        }
      }

    componentDidMount() {
        this.isUserAuthenticated();
        this.handleUser();
        this.handleTrips();
        if (window.location.pathname === '/fr/home') {
            this.setState({
                page: 'Journal de voyage'
            })
        }
        if (window.location.pathname === '/it/home') {
            this.setState({
                page: 'Giornale di viaggio'
            })
        }
        setTimeout(()=>{
            this.reTokenizeUser();
        }, 3500)
    }

    reTokenizeUser = () => {
        if (this.state.userProfile.Email.includes('@')) {
            // this.props.history.push('/?A=' + this.state.userProfile.Pin + this.state.userProfile.ProjectCode + this.state.userProfile.Pin)
        } else {
            const url = window.location.host.includes('ag.allianceswisspass') ?  '/fr/' : window.location.host.includes('agquestionario') ? '/it/' :  '/de/';
            const link = url + '?A=' + this.state.userProfile.Pin + this.state.userProfile.ProjectCode + this.state.userProfile.Pin;
            // console.log('LINK: ', link);
            this.props.history.push(link)
        }
    }

    userLanguage = () => {
        if (this.state.userProfile.Language === 1 && window.location.host === 'ga.allianceswisspass.ch') {
            console.log(' ');
        }
        if (this.state.userProfile.Language === 2 && window.location.host === 'ga.allianceswisspass.ch') {
            window.location.href = 'https://ag.allianceswisspass.ch/fr?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }
        if (this.state.userProfile.Language === 3 && window.location.host === 'ga.allianceswisspass.ch') {
            window.location.href = 'https://agquestionario.allianceswisspass.ch/it?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }
        
        if (this.state.userProfile.Language === 2 && window.location.host === 'ag.allianceswisspass.ch') {
            console.log(' ');
        }
        if (this.state.userProfile.Language === 1 && window.location.host === 'ag.allianceswisspass.ch') {
            window.location.href = 'https://ga.allianceswisspass.ch/fr?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }
        if (this.state.userProfile.Language === 3 && window.location.host === 'ag.allianceswisspass.ch') {
            window.location.href = 'https://agquestionario.allianceswisspass.ch/it?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }

        if (this.state.userProfile.Language === 3 && window.location.host === 'agquestionario.allianceswisspass.ch') {
            console.log(' ');
        }
        if (this.state.userProfile.Language === 1 && window.location.host === 'agquestionario.allianceswisspass.ch') {
            window.location.href = 'https://ga.allianceswisspass.ch/fr?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }
        if (this.state.userProfile.Language === 2 && window.location.host === 'agquestionario.allianceswisspass.ch') {
            window.location.href = 'https://agquestionario.allianceswisspass.ch/it?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }

        if (this.state.userProfile.Language === 1 && window.location.host === 'localhost:3000') {
            console.log(' ');
        }
        if (this.state.userProfile.Language === 2 && window.location.host === 'localhost:3000') {
            console.log(' ');
        }
        if (this.state.userProfile.Language === 3 && window.location.host === 'localhost:3000') {
            console.log(' ');
        }

        if (this.state.userProfile.Language === 1 && window.location.host === '725.dev.mxm.ch') {
            console.log(' ');
        }
        if (this.state.userProfile.Language === 2 && window.location.host === '725.dev.mxm.ch') {
            console.log(' ');
        }
        if (this.state.userProfile.Language === 3 && window.location.host === '725.dev.mxm.ch') {
            console.log(' ');
        }
    }

    handleTrips = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Journeys/Journeys'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
              tripsData: result
            });
        })
        .catch(error => console.log('error', error));
    }
    
    backButtonHandler = () => {
        if (this.state.page === 'Abschluss' && this.state.connectingPointScreen === null) {
            this.setState({
                page: 'Reisejournal'
            })
        }
        if (this.state.page === 'Terminer' && this.state.connectingPointScreen === null) {
            this.setState({
                page: 'Journal de voyage'
            })
        }
        if (this.state.page === 'Conclusione' && this.state.connectingPointScreen === null) {
            this.setState({
                page: 'Aggiungere viaggio'
            })
        }
        if (this.state.page === 'Reisejournal' && this.state.connectingPointScreen === null) {
            this.props.history.push('/de')
        }
        if (this.state.page === 'Journal de voyage' && this.state.connectingPointScreen === null) {
            this.props.history.push('/fr')
        }
        if (this.state.page === 'Aggiungere viaggio' && this.state.connectingPointScreen === null) {
            this.props.history.push('/it')
        }
        if (this.state.page === 'Datum' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Reisejournal'
            })
        }
        if (this.state.page === 'Date' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Journal de voyage'
            })
        }
        if (this.state.page === 'Data' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Aggiungere viaggio'
            })
        }
        if (this.state.page === 'Startort' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Datum'
            })
        }
        if (this.state.page === 'Lieu de départ' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Date'
            })
        }
        if (this.state.page === 'Luogo di partenza' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Data'
            })
        }
        if (this.state.page === 'Reise Bearbeiten' && this.state.connectingPointScreen === null) {
            this.setState({
                page: 'Reisejournal'
            })
        }
        if (this.state.page === 'Zielort' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Startort'
            })
        }
        if (this.state.page === 'Lieu d\'arrivée' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Lieu de départ'
            })
        }
        if (this.state.page === 'Destinazione' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Luogo di partenza'
            })
        }
        if (this.state.page === 'Umsteigeorte' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Zielort'
            })
        }
        if (this.state.page === 'Lieux de correspondance' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Lieu d\'arrivée'
            })
        }
        if (this.state.page === 'Punti di cambio' && this.state.connectingPointScreen === null)  {
            this.setState({
                page: 'Destinazione'
            })
        }
        if (this.state.page === 'Umsteigeorte' && this.state.connectingPointScreen === 'newPoint') {
            this.setState({
                connectingPointScreen: null
            })
        }
        if (this.state.page === 'Lieux de correspondance' && this.state.connectingPointScreen === 'newPoint') {
            this.setState({
                connectingPointScreen: null
            })
        }
        if (this.state.page === 'Punti di cambio' && this.state.connectingPointScreen === 'newPoint') {
            this.setState({
                connectingPointScreen: null
            })
        }
        if (this.state.page === 'Transportmittel' && this.state.connectingPointScreen === 'transportType') {
            this.setState({
                connectingPointScreen: null
            })
        }
        if (this.state.page === 'Lieux de correspondance' && this.state.connectingPointScreen === 'transportType') {
            this.setState({
                connectingPointScreen: null
            })
        }
        if (this.state.page === 'Punti di cambio' && this.state.connectingPointScreen === 'transportType') {
            this.setState({
                connectingPointScreen: null
            })
        }
    }

    isUserAuthenticated = () => {
        var auth = localStorage.AccessToken
        if (auth === undefined) {
            localStorage.clear();
            this.props.history.push('/fr/')
        }
    }

    handleUser = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/authentication/userInfo'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
                userProfile: result.Preload,
            });
        })
        .catch(error => console.log('error', error));
    }

    handleNewJourney = (e) => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Journeys/new'

            var myHeaders = new Headers();
            myHeaders.append("Authorization", window.localStorage.AccessToken);
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Cookie", "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81");

        this.setState({loading: true});
        if (this.state.ExtraStops.length < 1) {
            var raw = JSON.stringify({
                "JourneyId":"0",
                "PeriodEnd": this.state.userProfile.PeriodEnd,
                "PeriodStart": this.state.userProfile.PeriodStart,
                "JourneyDateString": this.state.date,
                "TypeNum":"1",
                "DepartureStationId": this.state.startPointCode,
                "DepartureStationName":this.state.startPointName,
                "MeansOfTravel":this.state.startTravel,
                "ArrivalStationId":this.state.endPointCode,
                "ArrivalStationName":this.state.endPointName
            });
    
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            
            fetch(proxyUrl + targetUrl, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .then(
                setTimeout(()=>{
                    window.location.reload()
                }, 5000)
            )
            .catch(error => console.log('error', error));
            this.handleHomeScreen('Reisejournal');
        } else {
            // eslint-disable-next-line

            if (e === undefined) {
                // eslint-disable-next-line
                var raw = JSON.stringify({
                    "JourneyId":"0",
                    "PeriodEnd": this.state.userProfile.PeriodEnd,
                    "PeriodStart": this.state.userProfile.PeriodStart,
                    "JourneyDateString": this.state.date,
                    "TypeNum":"1",
                    "DepartureStationId": this.state.startPointCode,
                    "DepartureStationName":this.state.startPointName,
                    "MeansOfTravel":this.state.startTravel,
                    "ArrivalStationId":this.state.endPointCode,
                    "ArrivalStationName":this.state.endPointName,
                    "ExtraStops": this.state.ExtraStops
                });
            } else {
                // eslint-disable-next-line
                var raw = JSON.stringify({
                    "JourneyId":"0",
                    "PeriodEnd": this.state.userProfile.PeriodEnd,
                    "PeriodStart": this.state.userProfile.PeriodStart,
                    "JourneyDateString": this.state.date,
                    "TypeNum":"1",
                    "DepartureStationId": this.state.startPointCode,
                    "DepartureStationName":this.state.startPointName,
                    "MeansOfTravel":this.state.startTravel,
                    "ArrivalStationId":this.state.endPointCode,
                    "ArrivalStationName":this.state.endPointName,
                    "ExtraStops": this.state.ExtraStops
                });
            }
            
            

            // eslint-disable-next-line
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            
            fetch(proxyUrl + targetUrl, requestOptions)
            .then(response => response.text())
            .then(result => console.log(result))
            .then(
                setTimeout(()=>{
                    window.location.reload()
                }, 5000)
            )
            .catch(error => console.log('error', error));
            this.handleHomeScreen('Reisejournal');
        }
    }
      
    addTrip = () => {
        window.location.pathname.includes('/fr') ?
        this.setState({
            page: 'Date',
        })
        : window.location.pathname.includes('/it') ?
        this.setState({
            page: 'Data',
        })
        :
        this.setState({
            page: 'Datum',
        })
    }

    handleDateChange = (e) => {
        let newDate = new Date(e)
        window.location.pathname.includes('/fr') ?
        this.setState({
            date: newDate,
            page: 'Lieu de départ'
        })
        : window.location.pathname.includes('/it') ?
        this.setState({
            date: newDate,
            page: 'Luogo di partenza'
        })
        :
        this.setState({
            date: newDate,
            page: 'Startort'
        })
    }
    
    handleDateUpdate = (e) => {
        let newDate = new Date(e)
        this.setState({
            date: newDate,
        })
    }

    handleStartPointUpdate = (e, z) => {
        this.setState({
            startPointName: e,
            startPointCode: z,
        })
    }

    handleViaStationUpdate = (index, id, code, name, mt) => {
        const station = {index: 0, MeansOfTravel: mt, MeansOfTravelIcon: mt, MeansOfTravelLabel: mt, RenderHelp: false, ViaId: id, ViaStationId: code, viaStationName: name}
        const editPoint = this.state.ExtraStops.slice();
        editPoint.splice(index + 1, 0, station);
        setTimeout(()=>{
            editPoint.splice(index, 1)
            this.setState({
                ExtraStops: editPoint
            })
        }, 200)
    }

    handleViaStationTransportTypeUpdate = (id, oldViaStationId, oldViaStationName, mt) => {
        let i = {index: 0, MeansOfTravel: mt, MeansOfTravelIcon: mt, MeansOfTravelLabel: mt, RenderHelp: false, ViaId: id, ViaStationId: oldViaStationId, viaStationName: oldViaStationName};
        this.setState({
            ExtraStops: this.state.ExtraStops.map(el => (el.ViaStationId === oldViaStationId ? Object.assign(i) :el ))
        })
    }

    handleViaStationTravelType = (stationId, stationName, travelType) => {
        let i = {index: '0', MeansOfTravel: travelType, MeansOfTravelIcon: travelType, MeansOfTravelLabel: travelType, RenderHelp: false, ViaId: '0', ViaStationId: stationId, viaStationName: stationName};
        this.setState({
            toSubmitStops: this.state.toSubmitStops.push(i)
        })
    }

    viaStationSubmit = () => {
        this.setState({
            ExtraStops: this.state.toSubmitStops
        })
    }

    handleEndPointUpdate = (e, z) => {
        this.setState({
            endPointName: e,
            endPointCode: z,
        })
    }
    
    handleStartPoint = (e, z) => {
        window.location.pathname.includes('/fr') ?
        this.setState({
            startPointName: e,
            startPointCode: z,
            page: 'Lieu d\'arrivée'
        })
        : window.location.pathname.includes('/it') ?
        this.setState({
            startPointName: e,
            startPointCode: z,
            page: 'Destinazione'
        })
        :
        this.setState({
            startPointName: e,
            startPointCode: z,
            page: 'Zielort'
        })
    }

    handleEditSubmit = (deleteId) => {
        if (this.state.ExtraStops > 0) {
            this.handleDeleteTrip(deleteId);
            setTimeout(()=>{
                this.handleNewJourney();
            }, 2000);
        } else {
            this.putJourney();
        }
    }

    handleConnectingPoint = (e, z) => {
        const station = { ViaId: '0', Index: '0', RenderHelp: 'false', ViaStationId: z, ViaStationName: e};
        const newStation = this.state.initExtraStops.slice();
        newStation.splice(this.state.connectingPointIndex, 0, station);
        this.setState(
            {
                initExtraStops: newStation,
                connectingPointScreen: null
            }
        )
    }

    handleExtraStationsState = (id, name, travel) => {
        this.setState({loading: true});
        const station = { ViaId: '0', Index: '0', RenderHelp: 'false', ViaStationId: id, ViaStationName: name, MeansOfTravel: travel};
        const newStation = this.state.initExtraStops;
        newStation.push(station);
        this.setState(
            {
                ExtraStops: newStation
            }
        )
    }

    removeInitConnectingPoint = (e) => {
        const stations = this.state.initExtraStops.filter(station => station.ViaStationName !== e);
        this.setState(
            {
                initExtraStops: stations
            }
        )
    }

    stationsPopper = () => {
        const stations = this.state.ExtraStops;

        stations.pop();

        this.setState(
            {
                ExtraStops: stations
            }
        )
    }

    removeConnectingPoint = (e) => {
        const stations = this.state.ExtraStops.filter(station => station.ViaStationName !== e);
        this.setState(
            {
                ExtraStops: stations
            }
        )
    }

    handleEndPoint = (e, z) => {
        window.location.pathname.includes('/fr') ?
        this.setState({
            endPointName: e,
            endPointCode: z,
            page: 'Lieux de correspondance'
        })
        : window.location.pathname.includes('/it') ?
        this.setState({
            endPointName: e,
            endPointCode: z,
            page: 'Punti di cambio'
        })
        :
        this.setState({
            endPointName: e,
            endPointCode: z,
            page: 'Umsteigeorte'
        })
    }

    handleConnectingPointScreen = (e, n, p) => {
        this.setState({
            connectingPointScreen: e,
            connectingPointIndex: n,
            page: p
        })
    }

    handleHomeScreen = (e) => {
        this.setState({
            page: e,
            connectingPointScreen: null
        })
    }

    handleStartTravel = (e) => {
        this.setState({
            startTravel: e
        })
    }

    handleEndTravel = (e) => {
        this.setState({
            endTravel: e
        })
    }

    handleDuplicateTrip = (date, spn, spc, epn, epc, st, es) =>{
        this.setState({
            loading: true,
            date: date,
            startPointName: spn,
            startPointCode: spc,
            endPointName: epn,
            endPointCode: epc,
            startTravel: st,
            ExtraStops: es
        });
        setTimeout(()=>{
            this.handleNewJourney();
        }, 1500);
    }

    handleTripBack = (e) => {
        let newDate = new Date(e)
        this.setState({
            date: newDate,
        });

        var lastTrip = this.state.tripsData[this.state.tripsData.length - 1]
        this.handleDuplicateTrip(newDate, lastTrip.ArrivalStationName, lastTrip.ArrivalStationId, lastTrip.DepartureStationName, lastTrip.DepartureStationId, lastTrip.MeansOfTravel, lastTrip.ExtraStops.reverse())        
    }

    handleTripBackWithExtra = (e) => {
        let newDate = new Date(e)
        this.setState({
            date: newDate,
        });

        var lastTrip = this.state.tripsData[this.state.tripsData.length - 1]
        var reverseStops = lastTrip.ExtraStops.reverse()

        // console.log('reverseStops: ', reverseStops);

        var sortedStops = [];

        reverseStops.map( (s, index) => (
            index < reverseStops.length - 1 ?
            sortedStops.push({'Index': s.Index, 'MeansOfTravel': reverseStops[index + 1].MeansOfTravel, 'MeansOfTravelIcon': reverseStops[index + 1].MeansOfTravel, 'MeansOfTravelLabel': reverseStops[index + 1].MeansOfTravel, 'RenderHelp': s.RenderHelp, 'ViaId': s.ViaId, 'ViaStationId': s.ViaStationId, 'ViaStationName': s.ViaStationName})
            : sortedStops.push({'Index': s.Index, 'MeansOfTravel': lastTrip.MeansOfTravel, 'MeansOfTravelIcon': lastTrip.MeansOfTravel, 'MeansOfTravelLabel': lastTrip.MeansOfTravel, 'RenderHelp': s.RenderHelp, 'ViaId': s.ViaId, 'ViaStationId': s.ViaStationId, 'ViaStationName': s.ViaStationName})
        ))

        // console.log('sortedStops: ', sortedStops);

        this.handleDuplicateTrip(newDate, lastTrip.ArrivalStationName, lastTrip.ArrivalStationId, lastTrip.DepartureStationName, lastTrip.DepartureStationId, reverseStops[0].MeansOfTravel, sortedStops)        
    }

    handleDeleteTrip = (tripId) => {
        this.setState({loading: true});
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Journeys/delete'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "lang=de");

        var raw = JSON.stringify({
            "DeleteJourneyId": tripId
        });

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.text())
        .then(
            setTimeout(()=>{
                window.location.reload()
            }, 1000)
        )
        .catch(error => console.log('error', error));
    }

    handleEditTrip = (ID, d, spn, spc, epn, epc, st, es) => {
        this.setState({
            editTripId: ID,
            date: d,
            startPointName: spn,
            startPointCode: spc,
            endPointName: epn,
            endPointCode: epc,
            startTravel: st,
            ExtraStops: es,
            page: 'Reise Bearbeiten'
        })
    }

    handleNewViaStationUpdate = (index, id) => {
        const station = {index: 0, MeansOfTravel: null, MeansOfTravelIcon: null, MeansOfTravelLabel: null, RenderHelp: false, ViaId: id, ViaStationId: index, viaStationName: null};
        const newPoint = this.state.ExtraStops.slice();
        newPoint.splice(index, 0, station)
        this.setState({
            ExtraStops: newPoint
        })
    }

    onChangeHandler = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    }

    putJourney = () => {
        this.setState({loading: true});
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Journeys/edit'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Cookie", "lang=de; __RequestVerificationToken=LQVpuQYP15-heStJ9FHPW8zxkP_PQeu0RmMSVvjwi68WtlkoPGwUZRUbwvZs7QAgOxQ-fx9R2YPgBzxkNhDvts7TEoyiUvZy06t3l8TgJJ81");

        var raw = JSON.stringify({
            "JourneyId":this.state.editTripId,
            "PeriodEnd": this.state.userProfile.PeriodEnd,
            "PeriodStart": this.state.userProfile.PeriodStart,
            "JourneyDateString": this.state.date,
            "TypeNum":"1",
            "DepartureStationId": this.state.startPointCode,
            "DepartureStationName":this.state.startPointName,
            "MeansOfTravel":this.state.startTravel,
            "ArrivalStationId":this.state.endPointCode,
            "ArrivalStationName":this.state.endPointName,
            "ExtraStops":this.state.ExtraStops
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        
        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.text())
        .then(
            setTimeout(()=>{
                window.location.reload()
            }, 3000)
        )
        .catch(error => console.log('error', error));
    }

    finishHandler = (param) => {
        this.setState({
            page: param
        })
    }

    loadingOn = () =>{
        this.setState({loading: true});
    }

    loadingOff = () =>{
        this.setState({loading: false});
    }

    render() {
        console.log('STATE: ', this.state);
        return (
            <React.Fragment>
                <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
                {
                    this.state.page === 'Reisejournal' ?
                        <MainHomeScreen 
                            state={this.state} 
                            addTrip={this.addTrip} 
                            handleDuplicateTrip={this.handleDuplicateTrip}
                            handleDeleteTrip={this.handleDeleteTrip}
                            handleEditTrip={this.handleEditTrip}
                            finishHandler={this.finishHandler}
                            handleTripBack={this.handleTripBack}
                            handleTripBackWithExtra={this.handleTripBackWithExtra} />
                    : this.state.page === 'Journal de voyage' ?
                        <MainHomeScreen 
                        state={this.state} 
                        addTrip={this.addTrip} 
                        handleDuplicateTrip={this.handleDuplicateTrip}
                        handleDeleteTrip={this.handleDeleteTrip}
                        handleEditTrip={this.handleEditTrip}
                        finishHandler={this.finishHandler}
                        handleTripBack={this.handleTripBack}
                        handleTripBackWithExtra={this.handleTripBackWithExtra} />
                    : this.state.page === 'Giornale di viaggio' ?
                        <MainHomeScreen 
                        state={this.state} 
                        addTrip={this.addTrip} 
                        handleDuplicateTrip={this.handleDuplicateTrip}
                        handleDeleteTrip={this.handleDeleteTrip}
                        handleEditTrip={this.handleEditTrip}
                        finishHandler={this.finishHandler}
                        handleTripBack={this.handleTripBack}
                        handleTripBackWithExtra={this.handleTripBackWithExtra} />
                    : this.state.page === 'Datum' ?
                        <DatumScreen state={this.state} handleDateChange={this.handleDateChange} />
                    : this.state.page === 'Date' ?
                        <DatumScreen state={this.state} handleDateChange={this.handleDateChange} />
                    : this.state.page === 'Data' ?
                        <DatumScreen state={this.state} handleDateChange={this.handleDateChange} />
                    : this.state.page === 'Startort' ?
                        <StartortScreen state={this.state} handleStartPoint={this.handleStartPoint} />
                    : this.state.page === 'Lieu de départ' ?
                        <StartortScreen state={this.state} handleStartPoint={this.handleStartPoint} />
                    : this.state.page === 'Luogo di partenza' ?
                        <StartortScreen state={this.state} handleStartPoint={this.handleStartPoint} />
                    : this.state.page === 'Zielort' ?
                        <ZielortScreen state={this.state} handleEndPoint={this.handleEndPoint} />
                    : this.state.page === 'Lieu d\'arrivée' ?
                        <ZielortScreen state={this.state} handleEndPoint={this.handleEndPoint} />
                    : this.state.page === 'Destinazione' ?
                        <ZielortScreen state={this.state} handleEndPoint={this.handleEndPoint} />
                    : ((this.state.page === 'Umsteigeorte') || (this.state.page === 'Transportmittel')) ?
                        <UmsteigeorteScreen 
                            state={this.state} 
                            handleConnectingPointScreen={this.handleConnectingPointScreen} 
                            handleConnectingPoint={this.handleConnectingPoint} 
                            handleHomeScreen={this.handleHomeScreen}
                            handleStartTravel={this.handleStartTravel}
                            handleEndTravel={this.handleEndTravel}
                            handleNewJourney={this.handleNewJourney}
                            removeInitConnectingPoint={this.removeInitConnectingPoint}
                            handleExtraStationsState={this.handleExtraStationsState}
                            stationsPopper={this.stationsPopper}
                            handleViaStationTravelType={this.handleViaStationTravelType}
                            viaStationSubmit={this.viaStationSubmit}
                            loadingOn={this.loadingOn}
                            loadingOff={this.loadingOff} />
                    : this.state.page === 'Lieux de correspondance' ?
                        <UmsteigeorteScreen 
                            state={this.state} 
                            handleConnectingPointScreen={this.handleConnectingPointScreen} 
                            handleConnectingPoint={this.handleConnectingPoint} 
                            handleHomeScreen={this.handleHomeScreen}
                            handleStartTravel={this.handleStartTravel}
                            handleEndTravel={this.handleEndTravel}
                            handleNewJourney={this.handleNewJourney}
                            removeInitConnectingPoint={this.removeInitConnectingPoint}
                            handleExtraStationsState={this.handleExtraStationsState}
                            stationsPopper={this.stationsPopper}
                            handleViaStationTravelType={this.handleViaStationTravelType}
                            viaStationSubmit={this.viaStationSubmit}
                            loadingOn={this.loadingOn}
                            loadingOff={this.loadingOff} />
                    : this.state.page === 'Punti di cambio' ?
                        <UmsteigeorteScreen 
                            state={this.state} 
                            handleConnectingPointScreen={this.handleConnectingPointScreen} 
                            handleConnectingPoint={this.handleConnectingPoint} 
                            handleHomeScreen={this.handleHomeScreen}
                            handleStartTravel={this.handleStartTravel}
                            handleEndTravel={this.handleEndTravel}
                            handleNewJourney={this.handleNewJourney}
                            removeInitConnectingPoint={this.removeInitConnectingPoint}
                            handleExtraStationsState={this.handleExtraStationsState}
                            stationsPopper={this.stationsPopper}
                            handleViaStationTravelType={this.handleViaStationTravelType}
                            viaStationSubmit={this.viaStationSubmit}
                            loadingOn={this.loadingOn}
                            loadingOff={this.loadingOff} />
                    : this.state.page === 'Reise Bearbeiten' ?
                        <UpdateJourney 
                            state={this.state}
                            handleDateUpdate={this.handleDateUpdate}
                            handleStartTravel={this.handleStartTravel}
                            handleStartPointUpdate={this.handleStartPointUpdate}
                            handleEndPointUpdate={this.handleEndPointUpdate}
                            onChangeHandler={this.onChangeHandler}
                            putJourney={this.putJourney}
                            removeConnectingPoint={this.removeConnectingPoint}
                            handleViaStationUpdate={this.handleViaStationUpdate}
                            handleEditSubmit={this.handleEditSubmit}
                            handleViaStationTransportTypeUpdate={this.handleViaStationTransportTypeUpdate}
                            handleNewViaStationUpdate={this.handleNewViaStationUpdate} />
                    : this.state.page === 'Abschluss' ?
                        <FinishJourney loadingOn={this.loadingOn} loadingOff={this.loadingOff} state={this.state} />
                    : this.state.page === 'Terminer' ?
                        <FinishJourney loadingOn={this.loadingOn} loadingOff={this.loadingOff} state={this.state} />
                    : this.state.page === 'Conclusione' ?
                        <FinishJourney loadingOn={this.loadingOn} loadingOff={this.loadingOff} state={this.state} />
                    : null
                }
                <NavBar state={this.state} />
            </React.Fragment>
        )
    }
}

export default withRouter(Main);