import React, { Component } from "react";
import ForgotPin from '../../components/ForgotPin';
import "./styles.css";

export default class Login extends Component {
    render() {
        return (
            <div className="Main-app">
                <ForgotPin />
            </div>
        )
    }
}