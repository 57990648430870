import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./styles.css";
import AppBar from '../../Shared/AppBar';
import NavBar from '../../Shared/NavBar';
import axios from 'axios';
import Main from './Main';

class Information extends Component {
    constructor(props) {
        super(props);
        this.state = {
          page: 'FAQ',
          back: false,
          value: 'information'
        }
      }

    componentDidMount() {
        this.visitsChecker();
    }
    
    visitsChecker = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Get'
    
        axios.get(proxyUrl + targetUrl, {
            headers: { "Authorization": window.localStorage.AccessToken }
        })
        .then((response) => {
            this.setState({ visits: response.data });
            console.log('VISITS RESPONSE: ', response.data);
            this.visitsHandler();
        })
        .catch((error) => { console.log(error);
        })
      }

      visitsHandler = () => {
        if (this.state.visits.IsFAQPageVisited === true) {
          console.log('Welcome Back');
        } else {
          var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
              targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Post?page=FAQPage'
          
    
          var myHeaders = new Headers();
              myHeaders.append("Authorization", window.localStorage.AccessToken);
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow'
          };
    
          fetch(proxyUrl + targetUrl, requestOptions)
          .then(response => response.json())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
        }
      }

    isUserAuthenticated = () => {
        var auth = localStorage.AccessToken
        if (auth === undefined) {
            localStorage.clear();
            this.props.history.push('/')
        }
    }

    backButtonHandler = () => {
        if (this.state.page === 'Fragen und Kontakt') {
          this.props.history.push('home')
        }
    }

    render() {
        // console.log('INFOS PAGE STATE: ', this.state);
        
        return(
            <React.Fragment>
                <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
                <Main />
                <NavBar state={this.state} />
            </React.Fragment>
        )
    }
}

export default withRouter(Information);