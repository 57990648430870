import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import "./styles.css";

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

const quote = getRndInteger(1,6);

class Main extends Component {
    
  render() {
      // console.log('WELCOME PROPS: ', this.props.state);
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" disableGutters="true" className="welcomeDialog fadeDialog" >
                {
                  this.props.path === '/fr' ?
                    <Typography variant="h5" className="welcomeText fadeDown">
                      Bienvenue,
                    </Typography>
                  : this.props.path === '/it' ?
                    <Typography variant="h5" className="welcomeText fadeDown">
                      Benvenuta,
                    </Typography>
                  : 
                    <Typography variant="h5" className="welcomeText fadeDown">
                      Willkommen,
                    </Typography>
                }
                <Typography variant="h5" className="welcomeName fadeDown">
                    {this.props.state.Firstname} {this.props.state.Name}
                </Typography>
            </Container>
        <Container maxWidth="false" className={"loginScreen" + quote} disableGutters="true">
            {
              window.location.pathname.includes('/fr') ?
                <Container className="welcomeQuote fadeUp">
                  <Typography variant="body2">
                    Wusstest du schon:
                  </Typography>
                  {
                    quote === 1 ?
                      <Typography variant="h6">
                        In der Schweiz verkehren auf jeder Strecke durchschnittlich über 150 Züge pro Tag.
                      </Typography>
                    : quote === 2 ?
                      <Typography variant="h6">
                        In der Schweiz gibt es insgesamt rund 25’000 Haltestellen des öffentlichen Verkehrs.
                      </Typography>
                    : quote === 3 ?
                      <Typography variant="h6">
                        Am 7. August 1847 wurde die erste Bahnstrecke eröffnet, die komplett auf Schweizer Boden lag.
                      </Typography>
                    : quote === 4 ?
                      <Typography variant="h6">
                        Das seven25-Abo ist an Wochenenden und Feiertagen bis um 07:00 Uhr gültig.
                      </Typography>
                    :
                      <Typography variant="h6">
                        Für rund 1.- Franken pro Tag hast du mit dem seven25-Abo ab 19:00 Uhr freie Fahrt.
                      </Typography>
                  }
                </Container>
              : window.location.pathname.includes('/it') ?
                <Container className="welcomeQuote fadeUp">
                  <Typography variant="body2">
                    Wusstest du schon:
                  </Typography>
                  {
                    quote === 1 ?
                      <Typography variant="h6">
                        In der Schweiz verkehren auf jeder Strecke durchschnittlich über 150 Züge pro Tag.
                      </Typography>
                    : quote === 2 ?
                      <Typography variant="h6">
                        In der Schweiz gibt es insgesamt rund 25’000 Haltestellen des öffentlichen Verkehrs.
                      </Typography>
                    : quote === 3 ?
                      <Typography variant="h6">
                        Am 7. August 1847 wurde die erste Bahnstrecke eröffnet, die komplett auf Schweizer Boden lag.
                      </Typography>
                    : quote === 4 ?
                      <Typography variant="h6">
                        Das seven25-Abo ist an Wochenenden und Feiertagen bis um 07:00 Uhr gültig.
                      </Typography>
                    :
                      <Typography variant="h6">
                        Für rund 1.- Franken pro Tag hast du mit dem seven25-Abo ab 19:00 Uhr freie Fahrt.
                      </Typography>
                  }
                </Container>
              :
                <Container className="welcomeQuote fadeUp">
                  <Typography variant="body2">
                    Wusstest du schon:
                  </Typography>
                  {
                    quote === 1 ?
                      <Typography variant="h6">
                        In der Schweiz verkehren auf jeder Strecke durchschnittlich über 150 Züge pro Tag.
                      </Typography>
                    : quote === 2 ?
                      <Typography variant="h6">
                        In der Schweiz gibt es insgesamt rund 25’000 Haltestellen des öffentlichen Verkehrs.
                      </Typography>
                    : quote === 3 ?
                      <Typography variant="h6">
                        Am 7. August 1847 wurde die erste Bahnstrecke eröffnet, die komplett auf Schweizer Boden lag.
                      </Typography>
                    : quote === 4 ?
                      <Typography variant="h6">
                        Das seven25-Abo ist an Wochenenden und Feiertagen bis um 07:00 Uhr gültig.
                      </Typography>
                    :
                      <Typography variant="h6">
                        Für rund 1.- Franken pro Tag hast du mit dem seven25-Abo ab 19:00 Uhr freie Fahrt.
                      </Typography>
                  }
                </Container>
            }
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);