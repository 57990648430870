import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '../../Shared/AppBar';
import Main from './Main';
import NavBar from '../../Shared/NavBar';
import axios from 'axios';
import './styles.css';

class Statistics extends Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 'Statistik',
            back: false,
            value: 'statistics'
        }
    }

    componentDidMount = () => {
        this.visitsChecker();
    }

    visitsChecker = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Get'
    
        axios.get(proxyUrl + targetUrl, {
            headers: { "Authorization": window.localStorage.AccessToken }
        })
        .then((response) => {
            this.setState({ visits: response.data });
            console.log('VISITS RESPONSE: ', response.data);
            this.visitsHandler();
        })
        .catch((error) => { console.log(error);
        })
      }

      visitsHandler = () => {
        if (this.state.visits.IsStatisticPageVisited === true) {
          console.log('Welcome Back');
        } else {
          var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
              targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Post?page=StatisticPage'
          
    
          var myHeaders = new Headers();
              myHeaders.append("Authorization", window.localStorage.AccessToken);
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow'
          };
    
          fetch(proxyUrl + targetUrl, requestOptions)
          .then(response => response.json())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
        }
      }

    render() {
        return (
            <div>
                <React.Fragment>
                    <CssBaseline />
                    <AppBar state={this.state} />
                    <Main />
                    <NavBar state={this.state} />
                </React.Fragment>
            </div>
        )
    }
}

export default withRouter(Statistics);