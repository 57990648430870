import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { CssBaseline, Typography, Container, Button } from '@material-ui/core';
import AppBar from '../../Shared/AppBar';
import landingVisual from '../../assets/images/loginVisual.png';
import axios from 'axios';
import './styles.css';

class Main extends Component {

  constructor(props) {
    super(props);
    window.location.pathname.includes('/fr') ?
    this.state = {
      page: 'Se désabonner',
      back: true,
      visits: undefined,
      logCounter: 0
    }
    : window.location.pathname.includes('/it') ?
    this.state = {
      page: 'Annulla l\'iscrizione',
      back: true,
      visits: undefined,
      logCounter: 0
    }
    :
    this.state = {
      page: 'Willkommen',
      back: true,
      visits: undefined,
      logCounter: 0
    }
  }
  
  componentDidMount() {
    this.userLog();
    this.isUserAuthenticated();
    this.handleUser();
    this.visitsChecker();
  }

  userLog = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Authentication/UserLog'
      

      var myHeaders = new Headers();
          myHeaders.append("Authorization", window.localStorage.AccessToken);
      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
      };

      if (this.state.logCounter === 0) {
        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
        this.setState({
            logCounter: this.state.logCounter + 1
        })
        this.props.history.push('/home')   
      }
  }

  loadingScreen = () => {
    this.setState({
      loading: true
    })
  }

  handleUser = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/authentication/userInfo'

    var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(proxyUrl + targetUrl, requestOptions)
    .then(response => response.json())
    .then(result => {
      this.setState({
        userProfile: result.Preload,
      });
    })
    .catch(error => console.log('error', error));
  }

  visitsChecker = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Get'

    axios.get(proxyUrl + targetUrl, {
        headers: { "Authorization": window.localStorage.AccessToken }
    })
    .then((response) => {
        this.setState({ visits: response.data });
        console.log('VISITS RESPONSE: ', response.data);
        this.visitsHandler();
    })
    .catch((error) => { console.log(error);
    })
  }

  visitsHandler = () => {
    if (this.state.visits.IsLandingPageVisited === true) {
      console.log('Welcome Back');
    } else {
      var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
          targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Post?page=LandingPage'
      

      var myHeaders = new Headers();
          myHeaders.append("Authorization", window.localStorage.AccessToken);
      var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow'
      };

      fetch(proxyUrl + targetUrl, requestOptions)
      .then(response => response.json())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));
    }
  }
    
  isUserAuthenticated = () => {
    var auth = localStorage.AccessToken
    if (auth === undefined) {
      localStorage.clear();
      this.props.history.push('/')
    }
  }

  backButtonHandler = () => {
    localStorage.clear();
    window.location.reload();
  }

  render() {
    return (
      <React.Fragment>
        <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
        <CssBaseline />
        <Container maxWidth="false" disableGutters="true" className="homeBanner">
          <Typography variant="h6" className="homeBannerText">
            {
              window.location.pathname.includes('/fr') ?  
                'Umfrage zum seven25-Abo: auf welchen Strecken bist du gereist?' : 
              window.location.pathname.includes('/it') ? 
                'Umfrage zum seven25-Abo: auf welchen Strecken bist du gereist?' : 
                'Umfrage zum seven25-Abo: auf welchen Strecken bist du gereist?'
            }
          </Typography>
        </Container>
        <Container className="finishSection" maxWidth="md">
          <Typography className="landingCopy" >
            Im Rahmen dieser Umfrage möchten wir erfahren, welche Reisen du während zwei Wochen unternommen hast. 
            Diese Angaben werden benötigt, um die Einnahmen korrekt auf die Transportunternehmen zu verteilen. 
            Mit deiner Teilnahme hilfst du mit, diesen gesetzlich verankerten Auftrag zu erfüllen.
          </Typography>
          <Typography className="visualCopy" >
            Mitmachen lohnt sich - Unter den Teilnehmenden verlosen wir Gesamtpreise im Wert von 17'000.- Franken:
          </Typography>
          <img src={landingVisual} className="landingVisual" alt="Landing Visual" />
          <Typography className="landingCopy" >
            Bitte nimm auch an der Umfrage teil, wenn du während der definierten Erfassungsperiode nicht gereist bist. 
            Jede Teilnahme ist wichtig!
          </Typography>
          <Typography className="landingCopy" >
            Vielen Dank für deine Unterstützung
          </Typography>
          <Button onClick={() => this.props.history.push('/home')} className="landingButton" disableElevation variant="contained">{window.location.pathname.includes('/fr') ?  'Zur Umfrage' : window.location.pathname.includes('/it') ? 'Zur Umfrage' : 'Zur Umfrage'}</Button>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);