import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import "./styles.css";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
          stations: [],
          tripsData: [],
          uniqueFavs: null,
          searchField: '',
          display: 'hide',
          lat: '',
          lon: '',
          nearbyStation: ''
        }

        this.searchHandler = this.searchHandler.bind(this);
    }

    componentWillMount() {
        this.handleUser();
        setInterval(()=>{
            this.uniqueFavHandler();
        }, 500)
    }

    componentDidMount() {
        this.setState({
            searchField: this.props.state.endPointName
        });
        setTimeout(()=>{
            if (this.state.tripsData === null) {
            } else {
                this.dynamicTextSize();
            }
            this.keyNavigation();
            this.keyEnter();
        }, 500)
    }

    keyEnter = () => {
        document.addEventListener('keydown', function(event) {
            if (event.which === 13) {
                document.getElementsByClassName('selected')[0].click();
            }
        })
    }

    keyNavigation = () => {
        var ul = document.getElementById('list');
        var liSelected;
        var index = -1;
        var next;

        document.addEventListener('keydown', function(event) {
            var len = ul.getElementsByTagName('li').length;
            if(event.which === 40) {
                index++;
                //down 
                if (liSelected) {
			        removeClass(liSelected, 'selected');
                    next = ul.getElementsByTagName('li')[index];
                    if(typeof next !== undefined && index <= len) {
                        liSelected = next;
                    } else {
         	            index = 0;
                        liSelected = ul.getElementsByTagName('li')[0];
                    }
                    addClass(liSelected, 'selected');
                    // console.log(index);
                }
                else {
                    index = 0;
   	                liSelected = ul.getElementsByTagName('li')[0];
			        addClass(liSelected, 'selected');
                }
            }   else if (event.which === 38) {
                //up
                if (liSelected) {
			        removeClass(liSelected, 'selected');
                    index--;
                    // console.log(index);
                    next = ul.getElementsByTagName('li')[index];
                    if(typeof next !== undefined && index >= 0) {
                        liSelected = next;
                    } else {
            		    index = len;
                        liSelected = ul.getElementsByTagName('li')[len];
                    }
                    addClass(liSelected, 'selected');
                }   else {
                        index = 0;
   	                    liSelected = ul.getElementsByTagName('li')[len];
			            addClass(liSelected, 'selected');
                }
            }
        }, false);

        function removeClass(el, className) {
            if(el.classList) {
                el.classList.remove(className);
            } else {
                el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        };

        function addClass(el, className) {
            if(el.classList) {
                el.classList.add(className);
            } else {
                el.className += ' ' + className;
            }
        };
    }

    dynamicTextSize = () => {
            var button;
            var that;
            var textLength;

            this.state.uniqueFavs.slice(0, 8).map(station => (
                // eslint-disable-next-line
                button = document.getElementById('arrival' + station[1]),
                that = document.getElementById('arrival' + station[1]).innerHTML,
                // console.log('THAT: ', that),
                textLength = that.length,
                // console.log('TEXT LENGTH ARRIVALS: ', textLength),
                
                textLength > 30 ?
                    button.classList.add("font5")
                : textLength > 20 ?
                    button.classList.add("font10")
                : textLength > 10 ?
                    button.classList.add("font14")
                : null
            ))
    }

    getCurrentLocation = () => {
        // console.log('GETTING LOCATION');
        if ("geolocation" in navigator) {
            // console.log("Location Available");
            this.initGeolocation();
        } else {
            console.log("Location Not Available");
        }
    }

    initGeolocation = () => {
        var latitude = '';
        var longitude = '';
        let currentComponent = this;
        navigator.geolocation.getCurrentPosition(function(position) {
            latitude = position.coords.latitude;
            longitude = position.coords.longitude;
            window.localStorage.removeItem("Latitude");
            window.localStorage.removeItem("Longitude");
            // console.log("Latitude is :", latitude);
            // console.log("Longitude is :", longitude);
            window.localStorage.setItem('Latitude', latitude);
            window.localStorage.setItem('Longitude', longitude);
            var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
                targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Station/SearchStations?'

            var myHeaders = new Headers();
                myHeaders.append("Authorization", window.localStorage.AccessToken);

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(proxyUrl + targetUrl + 'longitude=' + window.localStorage.Longitude + '&latitude=' + window.localStorage.Latitude , requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result);
                result.Message === 'Stations are not found for the provided geo-location!' ?
                    currentComponent.setState({ stations: [] })
                :   currentComponent.setState({ stations: result })
            })
            .catch(error => {
                console.log('error', error);
            });
        });
        this.setState({
            lat: window.localStorage.Latitude,
            lon: window.localStorage.Longitude
        });
    }

    searchHandler(event){
        this.setState({
            [event.target.name]: event.target.value
        })
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Station/GetStations'

        axios.get(proxyUrl + targetUrl, {
            headers: { "Authorization": window.localStorage.AccessToken },
            params: { query: ' ' + event.target.value}
        })
        .then((response) => {
            this.setState({ stations: response.data });
        })
        .catch((error) => { console.log(error);
        })
    }

    handleUser = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Journeys/Journeys'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
                tripsData: result
            });
        })
        .catch(error => console.log('error', error));
    }

    uniqueFavHandler = () => {
        if (this.state.tripsData === null) {
            // console.log('NO TRIPS');
            this.setState({
                uniqueFav: null
            })
        } else {
            const arrivalName = [];
            const arrivalId = [];
            const departureName = [];
            const departureId = [];
            this.state.tripsData.map(station => (
                arrivalName.push(station.ArrivalStationName)
            ));
            this.state.tripsData.map(station => (
                arrivalId.push(station.ArrivalStationId)
            ));
            this.state.tripsData.map(station => (
                departureName.push(station.DepartureStationName)
            ));
            this.state.tripsData.map(station => (
                departureId.push(station.DepartureStationId)
            ));
            let uniqueArrName = [...new Set(arrivalName)];
            let uniqueArrId = [...new Set(arrivalId)];
            let uniqueDepName = [...new Set(departureName)];
            let uniqueDepId = [...new Set(departureId)];

            // console.log('uniqueArrName ON SET', uniqueArrName);
            // console.log('uniqueArrId ON SET', uniqueArrId);
            // console.log('uniqueDepName ON SET', uniqueDepName);
            // console.log('uniqueDepId ON SET', uniqueDepId);
            

            const joinAnDn = [];
            const joinAiDi = [];

            for (let index = 0; index < uniqueArrName.length; index++) {
                joinAnDn.push(uniqueArrName[index]);
                joinAnDn.push(uniqueDepName[index]);
                joinAiDi.push(uniqueArrId[index]);
                joinAiDi.push(uniqueDepId[index]);
            }

            // console.log('joinAnDn ON PUSH', joinAnDn);
            // console.log('joinAiDi ON PUSH', joinAiDi);
            
            const uniqueNames = [...new Set(joinAnDn)];
            const uniqueIds = [...new Set(joinAiDi)];

            // console.log('uniqueNames ON SET', uniqueNames);
            // console.log('uniqueIds ON SET', uniqueIds);

            const uniqueSuggestions = [];
    
            for (let index = 0; index < uniqueNames.length; index++) {
                uniqueSuggestions.push([uniqueNames[index], uniqueIds[index]])
            }

            // console.log('uniqueSuggestions ON PUSH', uniqueSuggestions);
    
            this.setState({
                uniqueFavs: uniqueSuggestions
            })
        }
    }

    handleInputFocus = () => {
        this.setState({
            display: 'block'
        })
    }

    handleInputBlur = () => {
        setTimeout(()=>{
            this.setState({
                display: 'hide'
            })
        }, 500)
    }

  render() {
    //console.log('PROPS: ', this.props);
    //console.log('STATE: ', this.state);
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className="StartortScreen" disableGutters="true">
            <Container maxWidth="false" disableGutters className="homeBanner">
                <Typography variant="h6" className="homeBannerText">
                    {window.location.pathname.includes('/fr') ?  'Veuillez indiquer le lieu d\'arrivée' : window.location.pathname.includes('/it') ? 'Registri la destinazione' : 'Bitte erfasse den Zielort der Reise'}
                </Typography>
            </Container>
            <Container maxWidth="false" disableGutters>
                <TextField onFocus={this.handleInputFocus} onBlur={this.handleInputBlur} value={this.state.searchField} name="searchField" autoComplete='off' id="von" label={window.location.pathname.includes('/fr') ?  'À / Destination' : window.location.pathname.includes('/it') ? 'A / Destinazione:' : 'Nach / Ziel:'} fullWidth className="searchField" onChange={this.searchHandler} />
                <ul id="list" className="searchResults">
                { // eslint-disable-next-line 
                <li onClick={() => this.getCurrentLocation()} className={"myLocation" + ' ' + this.state.display}><MyLocationIcon />{window.location.pathname.includes('/fr') ?  'Votre emplacement actuel' : window.location.pathname.includes('/it') ? 'Luogo attuale' : 'Deinen aktuellen Standort verwenden'}</li>
                }
                    {
                        this.state.stations.slice(0, 5).map( stations => (
                            <li onClick={() => this.props.handleEndPoint(stations.Name, stations.Code)}>{stations.Name}</li>
                        ))
                    }
                </ul>
                <Typography variant="body2" className="favouriteCopy" >{window.location.pathname.includes('/fr') ?  'Propositions / Favoris' : window.location.pathname.includes('/it') ? 'Proposte / Preferiti' : 'Vorschläge / Favoriten'}</Typography>
                <div className="connSuggestionsButton">
                {
                    this.state.uniqueFavs === null ?
                        null
                    : this.state.uniqueFavs.length > 0 ?
                        this.state.uniqueFavs.slice(0, 8).reverse().map(station => (
                            <Button onClick={() => this.props.handleEndPoint(station[0], station[1])} variant="contained" className="stationButton" disableElevation size="large">
                                <span id={"arrival" + station[1]}>{station[0]}</span>
                            </Button>
                        ))
                    : 
                        <Skeleton animation="wave" height={42} className="stationSkeleton" />
                }
                </div>
            </Container>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);