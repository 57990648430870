import React, { Component } from "react";
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { Typography, Button } from "@material-ui/core";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import { Link } from "react-router-dom";
import addButton from "../../../assets/icons/addButtonDE.svg";
import addButtonFR from "../../../assets/icons/addButtonFR.svg";
import addButtonIT from "../../../assets/icons/addButtonIT.svg";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import * as moment from 'moment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FileCopyOutlinedIcon from '@material-ui/icons/FileCopyOutlined';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import SwapHorizOutlinedIcon from '@material-ui/icons/SwapHorizOutlined';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DatePicker } from "@material-ui/pickers";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { makeStyles } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import axios from 'axios';
import 'moment/locale/fr';
import 'moment/locale/it';
import "./styles.css";

function start_and_end(str) {
  if (str.length > 25) {
    return str.substr(0, 10) + ' - ... - ' + str.substr(str.length-20, str.length);
  }
  return str;
}

export const useStyles = makeStyles({
	toolbar: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	}
});

// eslint-disable-next-line
const CustomToolbar = function (props) {

	const { date,
		isLandscape,
		openView,
		setOpenView,
		title} = props;

	const handleChangeViewClick = (view) => (e) => {

		setOpenView(view);

	}

	const classes = useStyles();

	return (
		<PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
			<ToolbarButton
				onClick={handleChangeViewClick("year")}
				variant="h6"
				label={date.format("YYYY")}
				selected={openView === "year"}
			/>
			<ToolbarButton
				onClick={handleChangeViewClick("date")}
				variant="h4"
				selected={openView === "date"}
				label={date.format("dddd, D. MMM")}
			/>
		</PickerToolbar>
	);

}

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
          dialog: window.localStorage.dialog,
          tripsData: '',
          dD: null,
          DepartureStationName: null,
          DepartureStationId: null,
          ArrivalStationName: null,
          ArrivalStationId: null,
          MeansOfTravel: null,
          ExtraStops: null,
          screenWidth: null,
          help: 'firstTimeOutHome'
        };
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
      }

      dialogHandle = () => {
        window.localStorage.setItem('dialog', 'firstTimeOut');
        window.localStorage.setItem('dateTime', new Date())
        this.setState({dialog: 'firstTimeOut'})
      }
      
      dialogHandleOpen = () => {
        window.localStorage.removeItem('dialog');
        this.setState({dialog: undefined})
      }

      dialogChecker = () => {
        var diff = 0;
        diff = moment(window.localStorage.dateTime).diff(new Date(), 'days');
        
        if (Math.abs(diff) > 7) {
          this.dialogHandleOpen();
        }
      }

      componentDidMount() {
        this.handleUser();
        this.dialogChecker();
        window.addEventListener("resize", this.updateWindowDimensions());
        this.visitsChecker();
      }

      visitsChecker = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Get'
    
        axios.get(proxyUrl + targetUrl, {
            headers: { "Authorization": window.localStorage.AccessToken }
        })
        .then((response) => {
            this.setState({ visits: response.data });
            console.log('VISITS RESPONSE: ', response.data);
            this.visitsHandler();
        })
        .catch((error) => { console.log(error);
        })
      }
    
      visitsHandler = () => {
        if (this.state.visits.IsTripPageVisited === true) {
          console.log('Welcome Back');
        } else {
          var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
              targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Post?page=TripPage'
          

          var myHeaders = new Headers();
              myHeaders.append("Authorization", window.localStorage.AccessToken);
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow'
          };

          fetch(proxyUrl + targetUrl, requestOptions)
          .then(response => response.json())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
        }
      }

      componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions)
      }

      updateWindowDimensions() {
        this.setState({ screenWidth: window.innerWidth });
      }

      handleUser = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Journeys/Journeys'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
              tripsData: result
            });
        })
        .catch(error => console.log('error', error));
    }

    updateConfirmation = (id) => {
      var element = document.getElementById('update' + id);
      element.classList.add("confirmUpdate");
      element.classList.remove("Update");
    }

    updateConfirmationCancel = (id) => {
      var element = document.getElementById('update' + id);
      element.classList.add("Update");
      element.classList.remove("confirmUpdate");
    }

    deleteConfirmation = (id) => {
      var element = document.getElementById('delete' + id);
      element.classList.add("confirmDelete");
      element.classList.remove("Delete");
    }

    deleteConfirmationCancel = (id) => {
      var element = document.getElementById('delete' + id);
      element.classList.add("Delete");
      element.classList.remove("confirmDelete");
    }

    editConfirmation = (id) => {
      var element = document.getElementById('edit' + id);
      element.classList.add("confirmEdit");
      element.classList.remove("Edit");
    }

    editConfirmationCancel = (id) => {
      var element = document.getElementById('edit' + id);
      element.classList.add("Edit");
      element.classList.remove("confirmEdit");
    }

    openDatePicker(){
      document.getElementById("datepicker").click();
    }

    updateDatePicker(e, dsn, dsi, asn, asi, mot, es){
      // console.log('DATE PICkER ID: ', e);
      document.getElementById("datepickerUpdate" + e).click();

      this.setState({
        DepartureStationName: dsn,
        DepartureStationId: dsi,
        ArrivalStationName: asn,
        ArrivalStationId: asi,
        MeansOfTravel: mot,
        ExtraStops: es
      })
    }

    handleDuplicateDate = (e) => {
      let newDate = new Date(e)
      this.setState({
          dD: newDate,
      });
      this.props.handleDuplicateTrip(
        newDate,
        this.state.DepartureStationName,
        this.state.DepartureStationId,
        this.state.ArrivalStationName,
        this.state.ArrivalStationId,
        this.state.MeansOfTravel,
        this.state.ExtraStops
      )
    }

    dialogHandleHome = () => {
      this.setState({help: 'firstTimeOutHome'})
    }
  
    dialogOpenHome = () => {
      this.setState({help: 'firstTimeInHome'})
    }

    render() {
        // console.log('State HOME ', this.state);
        var date = new Date();
        // eslint-disable-next-line
        var newDate = new Date(date);
        if (this.state.tripsData === null) {
          console.log('No Trips');
          
        } else {
          var lastTrip = this.state.tripsData[this.state.tripsData.length - 1] 
        }
        // console.log('LAST TRIP: ', lastTrip);
        // eslint-disable-next-line no-lone-blocks
        {
          window.location.pathname.includes('/fr') ?
            moment.locale('fr')
          : window.location.pathname.includes('/it') ?
            moment.locale('it')
          :
            moment.locale('de')
        }
        // eslint-disable-next-line
        var date = moment(this.props.state.userProfile.PeriodEnd);
        var dateStart = moment(this.props.state.userProfile.PeriodStart);
        var difference = dateStart.diff(date, 'days');
        var diffDays = [];
  
        while (difference < 1) {
            diffDays.push(difference);
            difference ++;
        };

        // console.log('TRIPS: ', this.state.tripsData);
        // console.log('DATE: ', diffDays);
        if (this.props.state.userProfile.Email === undefined) {
          // console.log('E');
        } else {
          // this.props.history.push('/?A=' + result.Preload.Pin + result.Preload.ProjectCode + result.Preload.Pin)
          // console.log('L');
        }
        
        return (
            <React.Fragment>
              <CssBaseline />
              {
                this.state.tripsData === null ?
                  <div>
                    {
                      window.location.pathname.includes('/fr') ?
                        <Typography component="div" variant="h6" className="welcomeBanner">
                          Veuillez saisir tous vos voyages en transports publics?
                        </Typography>
                      : window.location.pathname.includes('/fr') ?
                        <Typography component="div" variant="h6" className="welcomeBanner">
                          La preghiamo di registrare tuttii viaggi effettuati con mezzi di trasporto pubblici.
                        </Typography>
                      :
                        <Typography component="div" variant="h6" className="welcomeBanner">
                          Auf welchen Strecken bist du gereist?
                        </Typography>
                    }
                  </div>
                : this.state.tripsData.length > 0 ?
                  <div>
                  {
                    window.location.pathname.includes('/fr') ?
                        <Typography component="div" variant="h6" className="welcomeBanner">
                          Veuillez saisir tous vos voyages en transports publics?
                        </Typography>
                    : window.location.pathname.includes('/fr') ?
                        <Typography component="div" variant="h6" className="welcomeBanner">
                          La preghiamo di registrare tuttii viaggi effettuati con mezzi di trasporto pubblici.
                        </Typography>
                    :
                        <Typography component="div" variant="h6" className="welcomeBanner">
                          Auf welchen Strecken bist du gereist?
                        </Typography>
                  }
                  <Container maxWidth="false" disableGutters="true">
                    <Container maxWidth="false" disableGutters="true" className="tripsList">
                      {
                      diffDays.map( i => (
                        this.state.tripsData.map((trip, index) => (
                          moment(trip.JourneyDate).format('DD.MM.YYYY') === moment(date).add(i, 'day').format('DD.MM.YYYY') ?
                          <ExpansionPanel className="expanPan">
                            <ExpansionPanelSummary className="expanPanSum">
                              <List>
                                <ListItem className="listItem">
                                  <ListItemText 
                                    primary={
                                      this.state.screenWidth < 620 ?
                                        trip.ExtraStops.length > 0 ?
                                          // eslint-disable-next-line
                                          start_and_end(trip.DepartureStationName + ' - ' + '...' + ' - ' + trip.ArrivalStationName)
                                        :
                                          start_and_end(trip.JourneySummary)
                                        : trip.JourneySummary
                                    } 
                                    secondary={moment(trip.JourneyDate).format('dddd, D.MM.YYYY')} />
                                  <Button onClick={() => this.editConfirmation(trip.JourneyId)} >
                                    <EditOutlinedIcon className="homeInteractions" />
                                  </Button>
                                    <Button onClick={() => this.updateConfirmation(trip.JourneyId)} className="homeIntButton" >
                                    <FileCopyOutlinedIcon className="homeInteractions" />
                                  </Button>
                                  <Button onClick={() => this.deleteConfirmation(trip.JourneyId)} className="homeIntButton" >
                                    <DeleteOutlinedIcon className="homeInteractions" />
                                  </Button>
                                </ListItem>
                                <ListItem id={'delete' + trip.JourneyId} className="listItem Delete" >
                                  {
                                    window.location.pathname.includes('/fr') ?
                                      <ListItemText primary="Voulez-vous vraiment supprimer?" />
                                    : window.location.pathname.includes('/it') ?
                                      <ListItemText primary="Desidera davvero cancellare?" />
                                    :
                                      <ListItemText primary="Wirklich löschen?" />
                                  }
                                  <Button onClick={() => this.props.handleDeleteTrip(trip.JourneyId)}><CheckCircleOutlineRoundedIcon /></Button>
                                  <Button onClick={() => this.deleteConfirmationCancel(trip.JourneyId)}><HighlightOffRoundedIcon /></Button>
                                </ListItem>
                                <ListItem id={'edit' + trip.JourneyId} className="listItem Edit" >
                                  {
                                    window.location.pathname.includes('/fr') ?
                                      <ListItemText primary="Voulez-vous vraiment modifier?" />
                                    : window.location.pathname.includes('/it') ?
                                      <ListItemText primary="Desidera davvero modificare?" />
                                    :
                                      <ListItemText primary="Wirklich bearbeiten?" />
                                  }
                                  <Button onClick={() => this.props.handleEditTrip(
                                    trip.JourneyId,
                                    trip.JourneyDate,
                                    trip.DepartureStationName,
                                    trip.DepartureStationId,
                                    trip.ArrivalStationName,
                                    trip.ArrivalStationId,
                                    trip.MeansOfTravel,
                                    trip.ExtraStops
                                  )}>
                                    <CheckCircleOutlineRoundedIcon />
                                  </Button>
                                  <Button onClick={() => this.editConfirmationCancel(trip.JourneyId)}><HighlightOffRoundedIcon /></Button>
                                </ListItem>
                                <DatePicker
                                  autoOk="false"
                                  label="Clearable"
                                  inputVariant="filled"
                                  okLabel={window.location.pathname.includes('/fr') ?  'Continuer' : window.location.pathname.includes('/it') ? 'Avanti' : 'Weiter'}
                                  cancelLabel={window.location.pathname.includes('/fr') ?  'Interrompre' : window.location.pathname.includes('/it') ? 'Annullare' : 'Abbrechen'}
                                  TextFieldComponent={'button'}
                                  minDate={new Date(this.props.state.userProfile.PeriodStart)}
                                  maxDate={new Date(this.props.state.userProfile.PeriodEnd)}
                                  value={this.props.state.date}
                                  onChange={this.handleDuplicateDate}
                                  id={'datepickerUpdate' + trip.JourneyId}
                                  className="hiddenDatePickerInput"
                                  arrSt={trip.ArrivalStationName}
                                  arrId={trip.ArrivalStationId}
                                  dpNm={trip.DepartureStationName}
                                  dpId={trip.DepartureStationId}
                                  mT={trip.MeansOfTravel}
                                  esT={trip.ExtraStops}
                                  disableToolbar
                                />
                                <ListItem id={'update' + trip.JourneyId} className="listItem Update" >
                                  {
                                    window.location.pathname.includes('/fr') ?
                                      <ListItemText primary="Voulez-vous vraiment copier?" />
                                    : window.location.pathname.includes('/it') ?
                                      <ListItemText primary="Desidera davvero copiare?" />
                                    :
                                      <ListItemText primary="Wirklich kopieren?" />
                                  }
                                  <Button onClick={() => this.updateDatePicker(
                                    trip.JourneyId, 
                                    trip.DepartureStationName,
                                    trip.DepartureStationId,
                                    trip.ArrivalStationName,
                                    trip.ArrivalStationId,
                                    trip.MeansOfTravel,
                                    trip.ExtraStops)} >
                                    <CheckCircleOutlineRoundedIcon />
                                  </Button>
                                  <Button onClick={() => this.updateConfirmationCancel(trip.JourneyId)}><HighlightOffRoundedIcon /></Button>
                                </ListItem>
                                <DatePicker
                                  autoOk="false"
                                  label="Clearable"
                                  inputVariant="filled"
                                  okLabel={window.location.pathname.includes('/fr') ?  'Continuer' : window.location.pathname.includes('/it') ? 'Avanti' : 'Weiter'}
                                  cancelLabel={window.location.pathname.includes('/fr') ?  'Interrompre' : window.location.pathname.includes('/it') ? 'Annullare' : 'Abbrechen'}
                                  TextFieldComponent={'button'}
                                  minDate={new Date(this.props.state.userProfile.PeriodStart)}
                                  maxDate={new Date(this.props.state.userProfile.PeriodEnd)}
                                  value={this.props.state.date}
                                  onChange={lastTrip.ExtraStops.length > 0 ? this.props.handleTripBackWithExtra : this.props.handleTripBack}
                                  id='datepicker'
                                  className="hiddenDatePickerInput"
                                  arrSt={lastTrip.ArrivalStationName}
                                  arrId={lastTrip.ArrivalStationId}
                                  dpNm={lastTrip.DepartureStationName}
                                  dpId={lastTrip.DepartureStationId}
                                  mT={lastTrip.MeansOfTravel}
                                  esT={lastTrip.ExtraStops.reverse()}
                                  disableToolbar
                                />
                                {
                                  this.state.tripsData.length === index + 1 ?
                                    <ListItem onClick={this.openDatePicker} className="listItemReturning">
                                      <SwapHorizOutlinedIcon className="returnTripIcon" />
                                      {
                                        window.location.pathname.includes('/fr') ?
                                          <ListItemText primary="Ajouter un trajet de retour" />
                                        : window.location.pathname.includes('/it') ?
                                          <ListItemText primary="Aggiungere ritorno" />
                                        :
                                          <ListItemText primary="Rückfahrt hinzufügen" />
                                      }
                                      <AddCircleIcon className="returningAddIcon" />
                                    </ListItem>
                                  : null
                                }
                              </List>
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails>
                              <Typography>
                                { trip.JourneySummary }
                              </Typography>
                            </ExpansionPanelDetails>
                          </ExpansionPanel>
                          : null
                        ))
                      ))
                      }
                    </Container>
                  </Container>
                  </div>
                : <Container className="tripsList" maxWidth="false" disableGutters="true">
                    <Container maxWidth="false" disableGutters="true">
                      <List>
                        <ListItem className="topListItem">
                          <Skeleton animation="wave" className="skeletonText" />
                          <Typography className="spacer"></Typography>
                          <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                          <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                          <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                        </ListItem>
                        <ListItem className="bottomListItem">
                          <Skeleton animation="wave" className="skeletonTextSecond" />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem className="topListItem">
                          <Skeleton animation="wave" className="skeletonText" />
                          <Typography className="spacer"></Typography>
                          <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                          <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                          <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                        </ListItem>
                        <ListItem className="bottomListItem">
                          <Skeleton animation="wave" className="skeletonTextSecond" />
                        </ListItem>
                      </List>
                      <List>
                        <ListItem className="topListItem">
                          <Skeleton animation="wave" className="skeletonText" />
                          <Typography className="spacer"></Typography>
                          <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                          <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                          <Skeleton variant="rect" width={32} height={32} className="rectSkeleton" />
                        </ListItem>
                        <ListItem className="bottomListItem">
                          <Skeleton animation="wave" className="skeletonTextSecond" />
                        </ListItem>
                      </List>
                    </Container>
                  </Container>
              }
              <Container>
                <Card raised="true" elevation="3" className={"firstTimeDialog " + this.state.dialog}>
                  <CardContent>
                    <Typography className="closeIcon" onClick={this.dialogHandle}>
                      <CloseIcon fontSize="large" />
                    </Typography>
                    {
                      window.location.pathname.includes('/fr') ?
                        <Typography variant="h5" className="firstTimeDialogContent">
                          Veuillez saisir tous les voyages effectués avec votre 
                          abonnement général (AG) durant la période du 
                          {moment(this.props.state.userProfile.PeriodStart).format('DD.MM.YYYY')} au {moment(this.props.state.userProfile.PeriodEnd).format('DD.MM.YYYY')}. 
                          Indiquez toujours les arrêts et les lieux de correspondance précis. 
                          Veuillez finaliser votre journal de voyage  d'ici au 
                          {moment(this.props.state.userProfile.SubmissionDeadline).format('DD.MM.YYYY')}.
                        </Typography>
                      : window.location.pathname.includes('/it') ?
                        <Typography variant="h5" className="firstTimeDialogContent">
                          La preghiamo di registrare TUTTI i viaggi effettuati nel periodo dal 
                          {moment(this.props.state.userProfile.PeriodStart).format('DD.MM.YYYY')} al {moment(this.props.state.userProfile.PeriodEnd).format('DD.MM.YYYY')} 
                           con il suo abbonamento generale (AG). 
                          Indichi ogni volta la fermata esatta e tutti i punti di cambio. 
                          Concluda il suo giornale di viaggio entro il 
                          {moment(this.props.state.userProfile.SubmissionDeadline).format('DD.MM.YYYY')}.
                        </Typography>
                      :
                        <Typography variant="h5" className="firstTimeDialogContent">
                          Bitte erfasse ALLE Reisen mit öffentlichen Verkehrsmitteln 
                          (Bahn, Bus, Tram, Schiff etc.), die du im Zeitraum vom 
                          {' ' + moment(this.props.state.userProfile.PeriodStart).format('DD.MM.YYYY')} – {moment(this.props.state.userProfile.PeriodEnd).format('DD.MM.YYYY') + ' '} 
                          mit deinem seven25-Abo unternommen hast. Gib dabei jeweils die genaue Haltestelle und alle Umsteigeorte an. 
                          Schliesse dein Reisejournal bis am {moment(this.props.state.userProfile.SubmissionDeadline).format('DD.MM.YYYY')} ab.
                        </Typography>
                    }
                  </CardContent>
                </Card>
              </Container>
              <Container disableGutters="true" maxWidth="false" className="homeBottom">
                <InfoIcon onClick={this.dialogHandleOpen} className="helpModalButton homeHelp" />
                <Container maxWidth="false" className="addButton" onClick={this.props.addTrip} >
                {
                  window.location.pathname.includes('/fr') ?
                    <img src={addButtonFR} alt="AddButton" />
                  : window.location.pathname.includes('/it') ?
                    <img src={addButtonIT} alt="AddButton" />
                  :
                    <img src={addButton} alt="AddButton" />
                }
                </Container>
                <Link onClick={() => this.props.finishHandler(window.location.pathname.includes('/fr') ? 'Terminer' : window.location.pathname.includes('/it') ? 'Conclusione': 'Abschluss')} >
                  <BottomNavigation className="homeCompleteButton" style={{backgroundColor: "#EEEEEE"}}>
                  {
                    window.location.pathname.includes('/fr') ?
                      <BottomNavigationAction disabled="false" label="Finaliser le journal de voyage" />
                    : window.location.pathname.includes('/it') ?
                      <BottomNavigationAction disabled="false" label="Concludere il giornale di viaggio." />
                    :
                      <BottomNavigationAction disabled="false" label="Reisejournal abschliessen." />
                  }
                  </BottomNavigation>
                </Link>
              </Container>
            </React.Fragment>
        )
    }
}

export default Main;