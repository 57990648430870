import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import { Toolbar } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import 'moment/locale/de';
import "./styles.css";

class Main extends Component {

  constructor(props) {
    super(props);
    this.state = {
      dialog: null,
      tripsData: null,
      totalTrips: 0,
      datesRange: [],
      reason: null,
      userProfile: [],
      editting: false,
      firstname: '',
      lastname: '',
      sex: '',
      address1: ' ',
      address2: ' ',
      zip: '',
      place: '',
      country: '',
      email: '',
    }
  }
  
  componentDidMount() {
    this.handleUser();
    this.props.loadingOn();
    setTimeout(()=>{
      this.setStates();
      this.props.loadingOff();
    }, 1200)
  }
  
  setStates = () => {
    this.setState({
      firstname: this.state.userProfile.Firstname,
      lastname: this.state.userProfile.Name,
      sex: this.state.userProfile.Sex,
      address1: this.state.userProfile.Address1,
      address2: this.state.userProfile.Address2,
      zip: this.state.userProfile.ZipCode,
      place: this.state.userProfile.Place,
      country: this.state.userProfile.Country,
      email: this.state.userProfile.Email
    })
  }

  handleUser = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/authentication/userInfo'

    var myHeaders = new Headers();
    myHeaders.append("Authorization", window.localStorage.AccessToken);

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };

    fetch(proxyUrl + targetUrl, requestOptions)
    .then(response => response.json())
    .then(result => {
        this.setState({
            userProfile: result.Preload,
            user: result
        });
    })
    .catch(error => console.log('error', error));
  }

  onChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  apiCall = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
             targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Periodapi/Competition'
 
         var myHeaders = new Headers();
         myHeaders.append("Authorization", window.localStorage.AccessToken);
         myHeaders.append("Content-Type", "application/json");
         myHeaders.append("Cookie", "lang=de");
 
         var raw = JSON.stringify({
            "Firstname": this.state.firstname,
            "ZipCode": this.state.zip,
            "Gender": this.state.sex,
            "Lastname": this.state.lastname,
            "City": this.state.place,
            "Country": this.state.country,
            "Address": this.state.address1,
            "AddressAddition": this.state.address2
         });
 
         var requestOptions = {
             method: 'PUT',
             headers: myHeaders,
             body: raw,
             redirect: 'follow'
         };
         
         fetch(proxyUrl + targetUrl, requestOptions)
         .then(response => response.text())
         .then(localStorage.clear())
         .catch(error => console.log('error', error));
  }

  updatePersonal = () => {
    this.props.finishJourneyCompetition();
    setTimeout(()=>{
      this.apiCall();
    }, 2000);
    this.props.history.push('/vielendank');
  }

  render() {
    // console.log('CONTEST PROPS: ', this.props);
    // console.log('CONTEST STATE: ', this.state);
    
    

    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className="datumScreen" disableGutters="true">
        {
          window.location.pathname.includes('/fr') ?
            <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
              Veuillez vérifier vos données de contact.
            </Typography>
          : window.location.pathname.includes('/it') ?
            <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
              Verifichi i suoi dati di contatto.
            </Typography>
          :
            <Typography component="div" variant="h6" className="welcomeBanner questionsBanner">
              Bitte überprüfe deine Kontaktdaten.
            </Typography>
        }
            <Container className="settingsTopSection scrollContest">
              <Toolbar disableGutters="true" className="settingsToolbar">
                    <Container disableGutters="true">
                      <TextField onChange={this.onChangeHandler} name="firstname" className="editFields50" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Prénom' : window.location.pathname.includes('/it') ? 'Nome' : 'Vorname'} value={this.state.firstname} variant="outlined" />
                      <TextField className="editFields50" onChange={this.onChangeHandler} name="lastname" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Nom' : window.location.pathname.includes('/it') ? 'Cognome' : 'Name'} value={this.state.lastname} variant="outlined" />
                      <FormControl variant="outlined" className="editFields100">
                        <InputLabel>{window.location.pathname.includes('/fr') ?  'Formule' : window.location.pathname.includes('/it') ? 'Appellativo' : 'Anrede'}</InputLabel>
                        <Select name="sex" value={this.state.sex} onChange={this.onChangeHandler} label={window.location.pathname.includes('/fr') ?  'Formule' : window.location.pathname.includes('/it') ? 'Appellativo' : 'Anrede'} >
                          <MenuItem value={1}>{window.location.pathname.includes('/fr') ?  'Monsieur' : window.location.pathname.includes('/it') ? 'Signor' : 'Herr'}</MenuItem>
                          <MenuItem value={2}>{window.location.pathname.includes('/fr') ?  'Madame' : window.location.pathname.includes('/it') ? 'Signora' : 'Frau'}</MenuItem>
                        </Select>
                      </FormControl>
                      {
                        this.state.address1.length > 0 ?
                        // eslint-disable-next-line
                        <TextField className="editFields100" onChange={this.onChangeHandler} name="address1" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Adresse' : window.location.pathname.includes('/it') ? 'Indirizzo' : 'Adresse'} value={this.state.address1} value={this.state.address1} variant="outlined" />
                        :
                        <TextField className="editFields100" onChange={this.onChangeHandler} name="address1" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Adresse' : window.location.pathname.includes('/it') ? 'Indirizzo' : 'Adresse'} variant="outlined" />
                      }
                      {
                        this.state.address2.length > 0 ?
                        <TextField className="editFields100" onChange={this.onChangeHandler} name="address2" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Complément d\'adresse' : window.location.pathname.includes('/it') ? 'Supplemento di indirizzo' : 'Adresszusatz'} value={this.state.address2} variant="outlined" />
                        :
                        <TextField className="editFields100" onChange={this.onChangeHandler} name="address2" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Complément d\'adresse' : window.location.pathname.includes('/it') ? 'Supplemento di indirizzo' : 'Adresszusatz'} variant="outlined" />
                      }
                      <TextField className="editFields30" onChange={this.onChangeHandler} name="zip" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'NPA' : window.location.pathname.includes('/it') ? 'CAP ' : 'PLZ'} value={this.state.zip} variant="outlined" />
                      <TextField className="editFields50" onChange={this.onChangeHandler} name="place" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Lieu de résidence' : window.location.pathname.includes('/it') ? 'Residenza' : 'Wohnort'} value={this.state.place} variant="outlined" />
                      <TextField className="editFields20" onChange={this.onChangeHandler} name="country" id="outlined-basic" label={window.location.pathname.includes('/fr') ?  'Pays' : window.location.pathname.includes('/it') ? 'Paese ' : 'Land'} value={this.state.country} variant="outlined" />
                    </Container>
                  </Toolbar>
            </Container>
        </Container>
        <BottomNavigation onClick={() => this.updatePersonal()} className="homeCompleteButton connectingContinueButton" style={{backgroundColor: "#EEEEEE"}}>
            {
              window.location.pathname.includes('/fr') ?
                <Typography variant="body1" className="connectingContinueButtonContent" >
                  Participer
                </Typography>
              : window.location.pathname.includes('/it') ?
                <Typography variant="body1" className="connectingContinueButtonContent" >
                  Partecipare
                </Typography>
              :
                <Typography variant="body1" className="connectingContinueButtonContent" >
                  Teilnehmen
                </Typography>
            }
            <ArrowForwardIosSharpIcon />
        </BottomNavigation>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);