import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import LocomotiveIcon from '../../../assets/icons/locomotiveDark.svg';
import StopsIcon from '../../../assets/icons/stopsStatistic.svg';
import StreckeIcon from '../../../assets/icons/streckeStatistic.svg';
import ClockIcon from '../../../assets/icons/clockStatistic.svg';
import LongestIcon from '../../../assets/icons/longestStatistic.svg';
import co2Tree from '../../../assets/icons/co2Tree.svg';
import carTree from '../../../assets/icons/carTree.svg';
import axios from 'axios';
import moment from "moment";
import "./styles.css";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tripsData: [],
            visitedStations: 0,
            traveledDistance: 0,
            longestDistance: 0
        }
    }

    componentDidMount() {
        this.handleTrips();
    }

    travelDistanceHandler = () => {
        var param;
        var a;
        var o;
        var u;
        var e;
        var i;
        var ii;
        var c;
        var aa;
        var ee;
        var uu;
        var oo;
        var aaa;
        var eee;
        var uuu;
        var eeee;
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://www.distance24.org/route.json?stops='

        this.state.tripsData.map(t => (
            t.DepartureStationName.includes(' ') ?
               param = t.DepartureStationName.split(' ')[0]
               // eslint-disable-next-line
            :  param =  t.DepartureStationName,
            // eslint-disable-next-line
            t.ExtraStops.map( e => {
                e.ViaStationName.includes(' ') ?
                   param = param + '|' +  e.ViaStationName.split(' ')[0]
                :  param = param + '|' +  e.ViaStationName
            }),
    
            t.ArrivalStationName.includes(' ') ?
                param = param + '|' +  t.ArrivalStationName.split(' ')[0]
            :   param = param + '|' +  t.ArrivalStationName,

            a = param.replace(/ä/g, "a"),
            o = a.replace(/ö/g, "o"),
            u = o.replace(/ü/g, "u"),
            e = u.replace(/é/g, "e"),
            aa = e.replace(/à/g, "a"),
            ee = aa.replace(/è/g, "e"),
            uu = ee.replace(/ù/g, "u"),
            aaa = uu.replace(/â/g, "a"),
            eee = aaa.replace(/ê/g, "e"),
            i = eee.replace(/î/g, "i"),
            oo = i.replace(/ô/g, "o"),
            uuu = oo.replace(/û/g, "u"),
            c = uuu.replace(/ç/g, "c"),
            eeee = c.replace(/ë/g, "e"),
            ii = eeee.replace(/ï/g, "i"),
            
            axios.get(proxyUrl + targetUrl + ii)
            .then((response) => {
                this.setState({ traveledDistance: this.state.traveledDistance + response.data.distance });
                if (response.data.distance > this.state.longestDistance) {
                    this.setState({longestDistance: response.data.distance})
                }
            })
            .catch((error) => { console.log(error);
            })
        ))
    }

    handleTrips = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Journeys/Journeys'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
              tripsData: result
            });
            this.visitedStationsCounter();
            this.travelDistanceHandler()
        })
        .catch(error => console.log('error', error));
    }

    visitedStationsCounter = () => {
        var extraStops = 0

        this.state.tripsData.map( s => (
            s.ExtraStops.map( e => (
                extraStops = extraStops + 1
            ))
        ))

        this.setState({
            visitedStations: extraStops + this.state.tripsData.length * 2
        })
    }

  render() {
      console.log('Statistics State: ', this.state);
    return (
      <React.Fragment>
        <CssBaseline />
        <Container className="settingsScreen" maxWidth="false" disableGutters="true">
            <Container maxWidth="false" disableGutters="true" className="homeBanner">
                <Typography variant="h6" className="homeBannerText">
                  {window.location.pathname.includes('/fr') ?  'Vous pouvez modifier ici vos coordonnées pour l\'enquête et vos réglages de notifications.' : window.location.pathname.includes('/it') ? 'Qui può modificare i suoi dati di contatto per il sondaggio e le impostazioni delle notifiche.' : 'Statistiken zu deinen öV-Reisen'}
                </Typography>
                <Typography variant="h6" className="homeBannerText">
                  {window.location.pathname.includes('/fr') ?  'Vous pouvez modifier ici vos coordonnées pour l\'enquête et vos réglages de notifications.' : window.location.pathname.includes('/it') ? 'Qui può modificare i suoi dati di contatto per il sondaggio e le impostazioni delle notifiche.' : 'Hier findest du verschiedene Statistiken zu deinen zurückgelegten öV-Reisen während deiner Erfassungsperiode.'}
                </Typography>
            </Container>
            <Container className="statisticsContainer">
                <Grid container spacing={1}>
                    <Grid item xs={6}>
                        <Paper elevation={0} className="statisticPaper">
                            <img src={LocomotiveIcon} alt="LocomotiveIcon" />
                            <Typography className="statisticTitle" variant="body">
                                Fahrten
                            </Typography>
                            <Typography className="statisticValue">
                                {
                                    this.state.tripsData === null ?
                                    '0'
                                    : this.state.tripsData.length
                                }
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className="statisticPaper">
                            <img src={StreckeIcon} alt="StreckeIcon" />
                            <Typography className="statisticTitle" variant="body">
                                Strecke
                            </Typography>
                            <Typography className="statisticValue">
                                {this.state.traveledDistance} <span>km</span>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className="statisticPaper">
                            <img src={ClockIcon} alt="ClockIcon" />
                            <Typography className="statisticTitle" variant="body">
                                Zeit im ÖV
                            </Typography>
                            <Typography className="statisticValue">
                                {moment().startOf('day').add(this.state.traveledDistance / 80, 'hours').format('H')} <span>h</span>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6}>
                        <Paper elevation={0} className="statisticPaper">
                            <img src={StopsIcon} alt="StopsIcon" />
                            <Typography className="statisticTitle" variant="body">
                                Haltestellen
                            </Typography>
                            <Typography className="statisticValue">
                                {this.state.visitedStations} <span>besucht</span>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={0} className="statisticPaper">
                            <img src={LongestIcon} alt="LongestIcon" />
                            <Typography className="statisticTitle" variant="body">
                                Längste Strecke
                            </Typography>
                            <Typography className="statisticValue">
                            {moment().startOf('day').add(this.state.longestDistance / 80, 'hours').format('H.m')} <span>H</span> / {this.state.longestDistance} <span>km</span>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper elevation={0} className="statisticPaper co2Paper">
                            <img src={co2Tree} alt="co2Tree" />
                            <Typography className="statisticTitle" variant="body">
                                Fürs Klima
                            </Typography>
                            <Typography className="co2Text">
                                Mit der Nutzung des ÖV tust du der Umwelt etwas Gutes. 
                                Im Vergleich zum Auto hast du deine CO2-Emissionen um <b>{Math.round(this.state.traveledDistance * 0.122) + ' '} 
                                kg</b> reduziert, soviel wird von <b>{Math.round(Math.round(this.state.traveledDistance * 0.122) / 22)} Bäumen</b> im Jahr gebunden.
                            </Typography>
                            <Grid container className="co2Values" spacing={1}>
                                <Grid item xs={4}>
                                    <Typography className="co2Value">
                                        {Math.round(this.state.traveledDistance * 0.122)} <span>kg</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography>
                                        <img src={carTree} className="center" alt="co2Tree" />
                                    </Typography>
                                </Grid>
                                <Grid item xs={4}>
                                    <Typography className="co2Value baumen">
                                        {Math.round(Math.round(this.state.traveledDistance * 0.122) / 22)} <span>Bäumen</span>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);