import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import phoneActionDE from '../../../assets/icons/phoneActionDE.svg';
import phoneActionFR from '../../../assets/icons/phoneActionFR.svg';
import phoneActionIT from '../../../assets/icons/phoneActionIT.svg';
import "./styles.css";

class Main extends Component {

  render() {
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" disableGutters="true">
            {/* <Container maxWidth="false" disableGutters="true" className="homeBanner">
                <Typography variant="h6" className="homeBannerText">
                  Bitte wählen Sie die Art Ihres Anliegens aus.
                </Typography>
            </Container> */}
            <Container>
                {/* <Button onClick={() => this.props.feedbackHandler('1')} variant="contained" className="faqFirstButton" disableElevation size="large" fullWidth>
                  Fragen zur Fahrtenerfassung
                </Button>
                <Button onClick={() => this.props.feedbackHandler('2')} variant="contained" className="faqButton" disableElevation size="large" fullWidth>
                  Technische Störung
                </Button> */}
                {
                  window.location.pathname.includes('/fr') ? 
                    <Button onClick={() => window.open("https://www.sbb.ch/fr/bahnhof-services/nach-der-reise/kundendienst/kritik-lob.html")} variant="contained" className="faqButton" disableElevation size="large" fullWidth>
                      Éloges et suggestions concernant le produit AG
                    </Button>
                  : window.location.pathname.includes('/it') ? 
                    <Button onClick={() => window.open("https://www.sbb.ch/it/bahnhof-services/nach-der-reise/kundendienst/kritik-lob.html")} variant="contained" className="faqButton" disableElevation size="large" fullWidth>
                      Elogio e suggerimenti sul prodotto AG
                    </Button>
                  : 
                    <Button onClick={() => window.open("https://www.sbb.ch/de/bahnhof-services/nach-der-reise/kundendienst/kritik-lob.html")} variant="contained" className="faqButton" disableElevation size="large" fullWidth>
                      Lob und Anregungen zum Produkt seven25
                    </Button>
                }
            </Container>
            <Container className="policySection">
                {
                  window.location.pathname.includes('/fr') ? 
                    <Typography className="cookieLink">
                      Veuillez trouver notre déclaration de protection de données <a href="https://www.allianceswisspass.ch/fr/asp/Downloadsindex.php?section=downloads&download=19456" className="policyButton" disableElevation size="large" fullWidth>ici</a>.
                    </Typography>
                  : window.location.pathname.includes('/it') ? 
                    <Typography className="cookieLink">
                      Potete trovare la nostra politica sulla privacy <a href="https://www.allianceswisspass.ch/it/asp/Downloadsindex.php?section=downloads&download=19456" className="policyButton" disableElevation size="large" fullWidth>qui</a>.
                    </Typography>
                  : 
                    <Typography className="cookieLink">
                      Unsere Datenschutzerklärung findest du <a href="https://www.allianceswisspass.ch/de/asp/Downloadsindex.php?section=downloads&download=19456" className="policyButton" disableElevation size="large" fullWidth>hier</a>.
                    </Typography>
                }
            </Container>
            <Container className="phoneActionSection">
              <Typography className="phoneActionButton">
                <a href="tel:+41313592250">
                  {window.location.pathname.includes('/fr') ?  <img src={phoneActionFR} alt="Phone Action" /> : window.location.pathname.includes('/it') ? <img src={phoneActionIT} alt="Phone Action" /> : <img src={phoneActionDE} alt="Phone Action" />}
                </a>
              </Typography>
            </Container>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);