import React, { Component } from "react";
import "./styles.css";
import AppBar from '../../Shared/AppBar';
import Login from '../Login';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
          page: 'Login',
          back: false,
          prev: '',
          path: window.location.pathname.substring(0,3)
        }
    }
    
    
    componentDidMount = () => {
        if (window.location.pathname === '/de') {
            this.setState({
                page: 'Login'
            })
        }
        if (window.location.pathname === '/fr') {
            this.setState({
                page: 'Connexion'
            })
        }
        if (window.location.pathname === '/de') {
            this.setState({
                page: 'Login'
            })
        }
    }

    render() {
        // console.log('URL: ', window.location);
        
        return (
            <React.Fragment>
                <AppBar state={this.state}/>
                <Login state={this.state} />
            </React.Fragment>
        )
    }
}

export default Main;