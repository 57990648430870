import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import line from '../../../../assets/icons/vLines.svg';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import ArrowForwardIosSharpIcon from '@material-ui/icons/ArrowForwardIosSharp';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import train from '../../../../assets/icons/train.svg';
import tram from '../../../../assets/icons/tram.svg';
import bus from '../../../../assets/icons/bus.svg';
import boat from '../../../../assets/icons/boat.svg';
import andere from '../../../../assets/icons/andere.svg';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import "./styles.css";

class Main extends Component {
  constructor(props) {
    super(props);
    window.location.pathname.includes('/fr') ?
    this.state = {
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: '',
      toBahn: '',
      toTram: '',
      toBus: '',
      toSchiff: '',
      toAndere: '',
      dialogError: 'Veuillez sélectionner le moyen de transport utilisé',
      fade: 'out',
      eligible: false,
      dialog: 'firstTimeOut'
    }
    : window.location.pathname.includes('/it') ?
    this.state = {
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: '',
      toBahn: '',
      toTram: '',
      toBus: '',
      toSchiff: '',
      toAndere: '',
      dialogError: 'Selezioni il mezzo di trasporto utilizzato',
      fade: 'out',
      eligible: false,
      dialog: 'firstTimeOut'
    }
    :
    this.state = {
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: '',
      toBahn: '',
      toTram: '',
      toBus: '',
      toSchiff: '',
      toAndere: '',
      dialogError: 'Bitte wählen Sie das benutzte Transportmittel aus',
      fade: 'out',
      eligible: false,
      dialog: 'firstTimeOut'
    }
  }

  componentDidMount(){
    this.activeStationsHandler();
  }

  fromBahnHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: 'active',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: ''
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  fromTramHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: '',
      fromTram: 'active',
      fromBus: '',
      fromSchiff: '',
      fromAndere: ''
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  fromBusHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: '',
      fromTram: '',
      fromBus: 'active',
      fromSchiff: '',
      fromAndere: ''
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  fromSchiffHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: 'active',
      fromAndere: ''
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  fromAndereHandler = (e) => {
    this.props.handleStartTravel(e);
    this.setState({
      eligible: true,
      fromBahn: '',
      fromTram: '',
      fromBus: '',
      fromSchiff: '',
      fromAndere: 'active'
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  toBahnHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: 'active',
      toTram: '',
      toBus: '',
      toSchiff: '',
      toAndere: ''
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  toTramHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: '',
      toTram: 'active',
      toBus: '',
      toSchiff: '',
      toAndere: ''
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  toBusHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: '',
      toTram: '',
      toBus: 'active',
      toSchiff: '',
      toAndere: ''
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  toSchiffHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: '',
      toTram: '',
      toBus: '',
      toSchiff: 'active',
      toAndere: ''
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  toAndereHandler = (e) => {
    this.props.handleEndTravel(e);
    this.setState({
      eligible: true,
      toBahn: '',
      toTram: '',
      toBus: '',
      toSchiff: '',
      toAndere: 'active'
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  errorDialog = () => {
    window.scrollTo(0, 0);
    this.setState({
      fade: 'in'
    });
    setTimeout(()=>{
      this.setState({
        fade: 'out'
      });
    }, 2500);
  }

  continueClickHandler = () => {
    this.props.loadingOn();
    if (this.state.eligible === false ) {
      this.props.loadingOff();
      this.errorDialog();
    } else {
      this.props.state.initExtraStops.map( stop => (
        // eslint-disable-next-line
        this.props.handleViaStationTravelType(stop.ViaStationId, stop.ViaStationName, this.state[stop.ViaStationName + 'TravelType']),
        this.props.handleExtraStationsState(stop.ViaStationId, stop.ViaStationName, this.state[stop.ViaStationName + 'TravelType'] )
      ));
      this.props.viaStationSubmit();
      setTimeout(()=>{
        this.props.handleNewJourney('splist');
      }, 2000);
    }
  }

  activeStationsHandler = () => {
    this.props.state.initExtraStops.map( stop => (
      this.setState({
        [stop.ViaStationName + 'Bahn']: '',
        [stop.ViaStationName + 'Tram']: '',
        [stop.ViaStationName + 'Bus']: '',
        [stop.ViaStationName + 'Schiff']: '',
        [stop.ViaStationName + 'Andere']: '',
        [stop.ViaStationName + 'Eligibility']: false
      })
    ))
  }

  viaBahnStatHandler = (e, b) => {
    this.setState({
      [e + 'Bahn']: 'active',
      [e + 'Tram']: '',
      [e + 'Bus']: '',
      [e + 'Schiff']: '',
      [e + 'Andere']: '',
      [e + 'TravelType']: 'Bahn',
      [e + 'Eligibility']: true,
      eligible: true
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  viaTramStatHandler = (e, b) => {
    this.setState({
      [e + 'Bahn']: '',
      [e + 'Tram']: 'active',
      [e + 'Bus']: '',
      [e + 'Schiff']: '',
      [e + 'Andere']: '',
      [e + 'TravelType']: 'Tram',
      [e + 'Eligibility']: true,
      eligible: true
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  viaBusStatHandler = (e, b) => {
    this.setState({
      [e + 'Bahn']: '',
      [e + 'Tram']: '',
      [e + 'Bus']: 'active',
      [e + 'Schiff']: '',
      [e + 'Andere']: '',
      [e + 'TravelType']: 'Bus',
      [e + 'Eligibility']: true,
      eligible: true
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  viaSchiffStatHandler = (e, b) => {
    this.setState({
      [e + 'Bahn']: '',
      [e + 'Tram']: '',
      [e + 'Bus']: '',
      [e + 'Schiff']: 'active',
      [e + 'Andere']: '',
      [e + 'TravelType']: 'Schiff',
      [e + 'Eligibility']: true,
      eligible: true
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  viaAndereStatHandler = (e, b) => {
    this.setState({
      [e + 'Bahn']: '',
      [e + 'Tram']: '',
      [e + 'Bus']: '',
      [e + 'Schiff']: '',
      [e + 'Andere']: 'active',
      [e + 'TravelType']: 'Andere',
      [e + 'Eligibility']: true,
      eligible: true
    });
    setTimeout(()=>{
      this.elegibilityChecker();
    }, 200);
  }

  elegibilityChecker = () => {
    var activeFrom;

    this.state.fromAndere === 'active' ?
      activeFrom = true
    : this.state.fromBahn === 'active' ?
      activeFrom = true
    : this.state.fromBus === 'active' ?
      activeFrom = true
    : this.state.fromSchiff === 'active' ?
      activeFrom = true
    : this.state.fromTram === 'active' ?
      activeFrom = true
    : activeFrom = false

    this.props.state.initExtraStops.map( stop => (
      this.state[stop.ViaStationName + 'Eligibility'] === false && activeFrom === false ?
        this.setState(
          {eligible: false}
        )
      : this.state[stop.ViaStationName + 'Eligibility'] === true && activeFrom === false ?
        this.setState(
          {eligible: false}
        )
      : this.state[stop.ViaStationName + 'Eligibility'] === false && activeFrom === true ?
        this.setState(
          {eligible: false}
        )
      : 
        this.setState(
          {eligible: true}
        )
    ))
  }

  dialogHandle = () => {
    this.setState({dialog: 'firstTimeOut'})
  }

  dialogOpen = () => {
    this.setState({dialog: 'firstTimeIn'})
  }

  render() {
    // console.log('PROPS TRANSPORT TYPE: ', this.props.state);
    // console.log('STATE TRANSPORT TYPE: ', this.state);
    
    
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className="StartortScreen" disableGutters="true">
            <Container maxWidth="false" disableGutters className="homeBanner">
                <Typography variant="h6" className="homeBannerText">
                    {window.location.pathname.includes('/fr') ?  'Veuillez sélectionner le moyen de transport utilisé pour chaque partie du voyage' : window.location.pathname.includes('/it') ? 'Selezioni il mezzo di trasporto utilizzato per ogni tratta parziale' : 'Bitte wähle das benutzte Transportmittel für jede Teilstrecke aus.'}
                </Typography>
                <Typography variant="h6" className="homeBannerText">
                    {window.location.pathname.includes('/fr') ?  'Les parcours à pied et les courses effectuées en dehors des transports publics ne doivent pas être saisis' : window.location.pathname.includes('/it') ? 'I percorsi a piedi e le corse al di fuori dei trasporti pubblici non devono essere generalmente registrati' : 'Fusswege und Fahrten ausserhalb des öffentlichen Verkehrs sind generell nicht zu erfassen.'}
                </Typography>
            </Container>
            <InfoIcon onClick={this.dialogOpen} className="helpModalButton" />
            <Card raised="true" elevation="3" className={"helpTimeDialog " + this.state.dialog}>
              <CardContent>
                <Typography className="closeIcon helpExit" onClick={this.dialogHandle}>
                  <CloseIcon fontSize="large" />
                </Typography>
                <Typography variant="h5" className="finishDialogContent transportHelp">
                  <img src={train} alt="Train" />
                  <span>{window.location.pathname.includes('/fr') ?  'Train' : window.location.pathname.includes('/it') ? 'Ferrovia' : 'Bahn'}</span>
                </Typography>
                <Typography variant="h5" className="finishDialogContent transportHelp">
                  <img src={tram} alt="Tram" />
                  <span>{window.location.pathname.includes('/fr') ?  'Tram' : window.location.pathname.includes('/it') ? 'Tram' : 'Tram'}</span>
                </Typography>
                <Typography variant="h5" className="finishDialogContent transportHelp">
                  <img src={bus} alt="Bus" />
                  <span>{window.location.pathname.includes('/fr') ?  'Bus, car postal' : window.location.pathname.includes('/it') ? 'Autobus, AutoPostale' : 'Bus, Postauto'}</span>
                </Typography>
                <Typography variant="h5" className="finishDialogContent transportHelp">
                  <img src={boat} alt="Schiff" />
                  <span>{window.location.pathname.includes('/fr') ?  'Bateau' : window.location.pathname.includes('/it') ? 'Battello' : 'Schiff'}</span>
                </Typography>
                <Typography variant="h5" className="finishDialogContent transportHelp">
                  <img src={andere} alt="Andere" />
                  <span>{window.location.pathname.includes('/fr') ?  'Autres' : window.location.pathname.includes('/it') ? 'Altri' : 'Andere'}</span>
                </Typography>
              </CardContent>
            </Card>
            <Card raised="true" className={"errorDialog transportDialog fade-" + this.state.fade}>
              <CardContent className="errorContent">
                <Typography align="center">
                  {this.state.dialogError}
                </Typography>
              </CardContent>
            </Card>
            <Container maxWidth="false" className="transportType">
                <Typography variant="h5" className="transportationType">
                  {this.props.state.startPointName}
                </Typography>
                <Typography>
                  {
                    this.props.state.connectingPointName === null ?
                      <div>
                        <Typography className="transportationType">
                          <img src={line} alt="Add Station" className="noConnectionStop" />
                        </Typography>
                        <Typography className="transportationType">
                          <img onClick={() => this.fromBahnHandler('Bahn')} className={"transportTypeOverlay" + this.state.fromBahn} src={train} alt="Train" />
                          <img onClick={() => this.fromTramHandler('Tram')} className={"transportTypeOverlay" + this.state.fromTram} src={tram} alt="Tram" />
                          <img onClick={() => this.fromBusHandler('Bus')} className={"transportTypeOverlay" + this.state.fromBus} src={bus} alt="Bus" />
                          <img onClick={() => this.fromSchiffHandler('Schiff')} className={"transportTypeOverlay" + this.state.fromSchiff} src={boat} alt="Boat" />
                          <img onClick={() => this.fromAndereHandler('Andere')} className={"transportTypeOverlay" + this.state.fromAndere} src={andere} alt="andere" />
                        </Typography>
                        <Typography className="transportationType">
                          <img src={line} alt="Add Station" className="noConnectionStop" />
                        </Typography>
                      </div>
                    : <div>
                        <Typography variant="h5" className="connectionStop transportTypeConnectionStop">
                          <img src={line} alt="line" className="addConnectingStation" height="50px" />
                        </Typography>
                        <Typography className="transportationType">
                          <img onClick={() => this.fromBahnHandler('Bahn')} className={"transportTypeOverlay" + this.state.fromBahn} src={train} alt="Train" />
                          <img onClick={() => this.fromTramHandler('Tram')} className={"transportTypeOverlay" + this.state.fromTram} src={tram} alt="Tram" />
                          <img onClick={() => this.fromBusHandler('Bus')} className={"transportTypeOverlay" + this.state.fromBus} src={bus} alt="Bus" />
                          <img onClick={() => this.fromSchiffHandler('Schiff')} className={"transportTypeOverlay" + this.state.fromSchiff} src={boat} alt="Boat" />
                          <img onClick={() => this.fromAndereHandler('Andere')} className={"transportTypeOverlay" + this.state.fromAndere} src={andere} alt="andere" />
                        </Typography>
                        <Typography variant="h5" className="transportationType">
                          {
                            this.props.state.initExtraStops.map( stop => (
                              <div className="connectingStationsType" >
                                <Typography variant="h5" className="connectionStop transportTypeConnectionStop">
                                  <img src={line} alt="line" className="addConnectingStation" height="50px" />
                                </Typography>
                                <Typography variant="h5" className="transportationType">
                                  {stop.ViaStationName}
                                </Typography>
                                <Typography variant="h5" className="connectionStop transportTypeConnectionStop">
                                  <img src={line} alt="line" className="addConnectingStation" height="50px" />
                                </Typography>
                                <Typography className="transportationType">
                                  <img onClick={() => this.viaBahnStatHandler(stop.ViaStationName)} className={"transportTypeOverlay" + this.state[stop.ViaStationName + 'Bahn']} src={train} alt="Train" />
                                  <img onClick={() => this.viaTramStatHandler(stop.ViaStationName)} className={"transportTypeOverlay" + this.state[stop.ViaStationName + 'Tram']} src={tram} alt="Tram" />
                                  <img onClick={() => this.viaBusStatHandler(stop.ViaStationName)} className={"transportTypeOverlay" + this.state[stop.ViaStationName + 'Bus']} src={bus} alt="Bus" />
                                  <img onClick={() => this.viaSchiffStatHandler(stop.ViaStationName)} className={"transportTypeOverlay" + this.state[stop.ViaStationName + 'Schiff']} src={boat} alt="Boat" />
                                  <img onClick={() => this.viaAndereStatHandler(stop.ViaStationName)} className={"transportTypeOverlay" + this.state[stop.ViaStationName + 'Andere']} src={andere} alt="andere" />
                                </Typography>
                              </div>
                            ))
                          }
                        </Typography>
                        <Typography variant="h5" className="connectionStop transportTypeConnectionStop">
                          <img src={line} alt="line" className="addConnectingStation" height="50px" />
                        </Typography>
                      </div>
                  }
                  <Typography variant="h5" className="transportationType">
                    {this.props.state.endPointName}
                  </Typography>
                </Typography>
                <Typography variant="h5">
                  {this.props.state.endPoint}
                </Typography>
              </Container>
              <BottomNavigation onClick={() => this.continueClickHandler()} className="homeCompleteButton connectingContinueButton" style={{backgroundColor: "#EEEEEE"}}>
                <Typography variant="body1" className="connectingContinueButtonContent" >
                  {window.location.pathname.includes('/fr') ?  'Continuer' : window.location.pathname.includes('/it') ? 'Avanti' : 'Weiter'}
                </Typography>
                <ArrowForwardIosSharpIcon />
              </BottomNavigation>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);