import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { animateScroll } from "react-scroll";
import Button from '@material-ui/core/Button';
import { DatePicker } from "@material-ui/pickers";
import PickerToolbar from "@material-ui/pickers/_shared/PickerToolbar";
import ToolbarButton from "@material-ui/pickers/_shared/ToolbarButton";
import { makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import * as moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import "./styles.css";

export const useStyles = makeStyles({
	toolbar: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start"
	}
});

const CustomToolbar = function (props) {

	const { date,
		isLandscape,
		openView,
		setOpenView,
		title} = props;

	const handleChangeViewClick = (view) => (e) => {

		setOpenView(view);

	}

	const classes = useStyles();

	return (
		<PickerToolbar className={classes.toolbar} title={title} isLandscape={isLandscape}>
			<ToolbarButton
				onClick={handleChangeViewClick("year")}
				variant="h6"
				label={date.format("YYYY")}
				selected={openView === "year"}
			/>
			<ToolbarButton
				onClick={handleChangeViewClick("date")}
				variant="h4"
				selected={openView === "date"}
				label={date.format("dddd, D. MMM")}
			/>
		</PickerToolbar>
	);

}

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userProfile: [],
            datesRange: [],
            upCounter: 3,
            downCounter: 3,
            startDaysDiffLength: 0
        }
      }
    
    componentDidMount() {
        this.handleUser();
    }

    scrollToBottomClick = () => {
        animateScroll.scrollToBottom({
            containerId: "secondDiv"
        });
    };

    scrollToTopClick = () => {
        animateScroll.scrollToTop({
            containerId: "secondDiv"
        });
    };

    loadFuture = (param) => {
        if (this.state.upCounter + 3 < param + 1) {
            this.setState({
                upCounter: this.state.upCounter + 3
            })
        } else {
            this.setState({
                upCounter: this.state.upCounter + 2
            })
        }
        this.scrollToBottomClick();
    }

    loadPast = (param) => {
        if (this.state.downCounter + 3 < param+ 1) {
            this.setState({
                downCounter: this.state.downCounter + 3
            })
        } else {
            this.setState({
                downCounter: this.state.downCounter + 2
            })
        }
        this.scrollToTopClick();
    }

    handleUser = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/authentication/userInfo'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
                userProfile: result.Preload,
            });
        })
        .catch(error => console.log('error', error));
    }

  render() {
    //   console.log('DATUM PROPS: ', this.state.userProfile);
    //   console.log('DATUM PROPS: ', this.state.userProfile);
      // eslint-disable-next-line no-lone-blocks
      {
        window.location.pathname.includes('/fr') ?
          moment.locale('fr')
        : window.location.pathname.includes('/it') ?
          moment.locale('it')
        :
          moment.locale('de')
      }
      var date = moment(this.state.userProfile.PeriodEnd);
      var dateStart = moment(this.state.userProfile.PeriodStart);
    //   var difference = dateStart.diff(date, 'days');
      var difference = 0;
      var diffDays = [];
      var startDaysDiff = [];
      var startDifference = 0

      if (new Date(this.state.userProfile.PeriodEnd) > new Date()) {
        difference = ~date.diff(new Date(), 'days');
        console.log('diff: ', difference);
      } else {
        difference = dateStart.diff(date, 'days');
        console.log('diffElse: ', difference);
      }

      while (difference < 0) {
            // console.log('WHILE difference', difference);
            // console.log('WHILE diffDays', diffDays);
          diffDays.push(difference);
          difference ++;
      };

      var today = moment(new Date());

      if (new Date(this.state.userProfile.PeriodStart) < new Date()) {
        startDifference = ~today.diff(dateStart, 'days') + 1;
        console.log('diffStart: ', startDifference);
      } else {
        startDifference = dateStart.diff(date, 'days');
        console.log('diffElse: ', startDifference);
      }

      while (startDifference < 0) {
            // console.log('WHILE difference', difference);
            // console.log('WHILE diffDays', diffDays);
            startDaysDiff.push(startDifference);
            startDifference ++;
      };

    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className="datumScreen" disableGutters="true">
            {/* <Container maxWidth="false" disableGutters="true" className="homeBanner">
                {
                    window.location.pathname.includes('/fr') ?
                    <div>
                        <Typography variant="h6" className="homeBannerText">
                            Veuillez sélectionner la date
                        </Typography>
                        <Typography variant="h6" className="homeBannerText">
                            du voyage
                        </Typography>
                    </div>
                    : window.location.pathname.includes('/it') ?
                    <div>
                        <Typography variant="h6" className="homeBannerText">
                            Selezioni la data del
                        </Typography>
                        <Typography variant="h6" className="homeBannerText">
                            suo viaggio
                        </Typography>
                    </div>
                    :
                    <div>
                        <Typography variant="h6" className="homeBannerText">
                            Bitte wähle das Datum der Reise aus
                        </Typography>
                    </div>
                }
            </Container> */}
            <Container>
                <Button variant="contained" className="calendarButton" disableElevation size="large" fullWidth>
                    <DatePicker
                        autoOk="false"
                        label="Clearable"
                        inputVariant="filled"
                        disableToolbar
                        okLabel={window.location.pathname.includes('/fr') ?  'Continuer' : window.location.pathname.includes('/it') ? 'Avanti' : 'Weiter'}
                        cancelLabel={window.location.pathname.includes('/fr') ?  'Interrompre' : window.location.pathname.includes('/it') ? 'Annullare' : 'Abbrechen'}
                        TextFieldComponent={'button'}
                        minDate={new Date(this.props.state.userProfile.PeriodStart)}
                        maxDate={new Date(this.props.state.userProfile.PeriodEnd)}
                        value={this.props.state.date}
                        onChange={this.props.handleDateChange}
                        ToolbarComponent={CustomToolbar}
                    />
                    {window.location.pathname.includes('/fr') ?  'Ouvrir le calendrier' : window.location.pathname.includes('/it') ? 'Aprire il calendario' : 'Kalender öffnen'}
                </Button>
                <div>
                    {   
                        console.log('FUTURECOUNTER: ', this.state.upCounter)
                    }
                    {
                        date > new Date() ?
                        <div id="scrollableDates">
                            <Button id="scrollUp" onClick={() => this.loadPast(startDaysDiff.length)} disabled={this.state.downCounter < startDaysDiff.length ? false : true} variant="contained" className="arrowButtons" size="large" fullWidth>
                                <ExpandLessIcon />   
                            </Button>
                            <div id="secondDiv" className="scrollDates">
                                <div className="reverseOrder">
                                {
                                startDaysDiff.reverse().slice(0, this.state.downCounter).map( i => (
                                    <Button onClick={() => this.props.handleDateChange(new Date(moment().subtract(+Math.abs(i), 'day')))} variant="contained" className="dateButton" disableElevation size="large" fullWidth>
                                        {       // eslint-disable-next-line
                                                moment(moment().subtract(+ Math.abs(i), 'day')).day() === 6 
                                            ?   // eslint-disable-next-line
                                                moment().subtract(+Math.abs(i), 'day').format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment().subtract(+Math.abs(i + 1), 'day').format(' dd, DD. MMM ') + '07.00 Uhr'
                                            :   // eslint-disable-next-line
                                                moment(moment().subtract(+ Math.abs(i), 'day')).day() === 5 
                                            ?   // eslint-disable-next-line
                                                moment().subtract(+Math.abs(i), 'day').format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment().subtract(+Math.abs(i + 1), 'day').format(' dd, DD. MMM ') + '07.00 Uhr'
                                            :   // eslint-disable-next-line
                                                moment().subtract(+Math.abs(i), 'day').format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment().subtract(+Math.abs(i + 1), 'day').format(' dd, DD. MMM ') + '05.00 Uhr'
                                        }
                                    </Button>
                                ))
                                }
                                </div>
                                <Button id="todaysDate" onClick={() => this.props.handleDateChange(new Date())} variant="contained" className="dateButton" disableElevation size="large" fullWidth>
                                    {window.location.pathname.includes('/fr') ?  'Aujourd\'hui' : window.location.pathname.includes('/it') ? 'Oggi' : 'Heute'}
                                </Button>
                                <div className="reverseOrder">
                                {
                                diffDays.slice(diffDays.length - this.state.upCounter, diffDays.length).map( i => (
                                    <Button onClick={() => this.props.handleDateChange(new Date(moment().add(+Math.abs(i), 'day')))} variant="contained" className="dateButton" disableElevation size="large" fullWidth>
                                        {       // eslint-disable-next-line
                                                moment(moment().add(+ Math.abs(i), 'day')).day() === 6 
                                            ?   // eslint-disable-next-line
                                                moment().add(+Math.abs(i), 'day').format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment().add(+Math.abs(i - 1), 'day').format(' dd, DD. MMM ') + '07.00 Uhr' 
                                            : 
                                                moment(moment().add(+ Math.abs(i), 'day')).day() === 5 
                                            ?   // eslint-disable-next-line
                                                moment().add(+Math.abs(i), 'day').format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment().add(+Math.abs(i - 1), 'day').format(' dd, DD. MMM ') + '07.00 Uhr' 
                                            :   // eslint-disable-next-line
                                                moment().add(+Math.abs(i), 'day').format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment().add(+Math.abs(i - 1), 'day').format(' dd, DD. MMM ') + '05.00 Uhr'
                                        }
                                    </Button>
                                ))
                                }
                                </div>
                            </div>
                            <Button id="scrollDown" onClick={() => this.loadFuture(diffDays.length)} disabled={this.state.upCounter < diffDays.length ? false : true} variant="contained" className="arrowButtons" size="large" fullWidth>
                                <ExpandMoreIcon />   
                            </Button>
                        </div>
                        : <div id="scrollableDates">
                        <Button id="scrollUp" onClick={() => this.scrollToTopClick()} variant="contained" className="arrowButtons" size="large" fullWidth>
                            <ExpandLessIcon />   
                        </Button>
                        <div id="secondDiv" className="scrollDates">
                            <div className="reverseOrder">
                            {
                                diffDays.map( i => (
                                    <Button onClick={() => this.props.handleDateChange(new Date(moment(this.props.state.userProfile.PeriodEnd).subtract(+Math.abs(i), 'day')))} variant="contained" className="dateButton" disableElevation size="large" fullWidth>
                                        {       // eslint-disable-next-line
                                                moment(moment(this.props.state.userProfile.PeriodEnd).subtract(+ Math.abs(i), 'day')).day() === 6 
                                            ?   // eslint-disable-next-line
                                                moment(this.props.state.userProfile.PeriodEnd).subtract(+Math.abs(i), 'day').format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment(this.props.state.userProfile.PeriodEnd).subtract(+Math.abs(i + 1), 'day').format(' dd, DD. MMM ') + '07.00 Uhr'
                                            :   // eslint-disable-next-line
                                                moment(moment(this.props.state.userProfile.PeriodEnd).subtract(+ Math.abs(i), 'day')).day() === 5 
                                            ?   // eslint-disable-next-line
                                                moment(this.props.state.userProfile.PeriodEnd).subtract(+Math.abs(i), 'day').format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment(this.props.state.userProfile.PeriodEnd).subtract(+Math.abs(i + 1), 'day').format(' dd, DD. MMM ') + '07.00 Uhr'
                                            :   // eslint-disable-next-line
                                                moment(this.props.state.userProfile.PeriodEnd).subtract(+Math.abs(i), 'day').format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment(this.props.state.userProfile.PeriodEnd).subtract(+Math.abs(i + 1), 'day').format(' dd, DD. MMM ') + '05.00 Uhr'
                                        }
                                    </Button>
                                    ))
                            }
                            {
                                diffDays.slice(diffDays.length - 1, diffDays.length).map( i => (
                                    <Button onClick={() => this.props.handleDateChange(new Date(moment(this.props.state.userProfile.PeriodEnd)))} variant="contained" className="dateButton" disableElevation size="large" fullWidth>
                                        {       // eslint-disable-next-line
                                                moment(moment(this.props.state.userProfile.PeriodEnd)).day() === 6 
                                            ?   // eslint-disable-next-line
                                                moment(this.props.state.userProfile.PeriodEnd).format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment(this.props.state.userProfile.PeriodEnd).add(1, 'day').format(' dd, DD. MMM ') + '07.00 Uhr'
                                            :   // eslint-disable-next-line
                                                moment(moment(this.props.state.userProfile.PeriodEnd)).day() === 5 
                                            ?   // eslint-disable-next-line
                                                moment(this.props.state.userProfile.PeriodEnd).format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment(this.props.state.userProfile.PeriodEnd).add(1, 'day').format(' dd, DD. MMM ') + '07.00 Uhr'
                                            :   // eslint-disable-next-line
                                                moment(this.props.state.userProfile.PeriodEnd).format('dd, DD. MMM ') + '19.00 Uhr ' + ' - ' + moment(this.props.state.userProfile.PeriodEnd).add(1, 'day').format(' dd, DD. MMM ') + '05.00 Uhr'
                                        }
                                    </Button>
                                    ))
                            }
                            </div>
                        </div>
                        <Button id="scrollDown" onClick={() => this.scrollToBottomClick()} variant="contained" className="arrowButtons" size="large" fullWidth>
                            <ExpandMoreIcon />   
                        </Button>
                    </div>
                    }
                </div>
            </Container>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);