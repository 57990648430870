import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Typography, Card, Container, CssBaseline, CardContent, Button } from "@material-ui/core";
import "./styles.css";
import axios from 'axios';
import querystring from 'querystring';

class Main extends Component {

  constructor() {
		super()
		this.state = {
      dialogError: '',
      fade: 'out',
      secondStep: 'secondStepLogin'
		}
  }

  componentDidMount() {
    this.languageRouter();

    if (localStorage.AccessToken === undefined) {
      localStorage.clear(); 
    } else {
      this.props.history.push(window.location.pathname + '/home');
    }

    if (this.props.location.search === '?locked') {
      window.location.pathname === '/fr' ?
        this.setState({
          dialogError: 'Le journal de voyage a déjà été finalisé, il n\'est plus possible de le modifier.',
          fade: 'in'
        })
      : window.location.pathname === '/it' ?
        this.setState({
          dialogError: 'Il giornale di viaggio è già concluso. Non sono più consentite modifiche.',
          fade: 'in'
        })
      : this.setState({
          dialogError: 'Das Reisejournal ist bereits abgeschlossen. Änderungen sind nicht mehr möglich.',
          fade: 'in'
        })
    }

    // console.log('HOST: ', window.location.pathname );
    // console.log('PATH: ', window.location.pathname );
    

    let params = querystring.parse(this.props.location.search);
    // console.log('PARAMS: ', params["?A"]);


    if (params["?TNC"] === undefined) {
      // console.log('Params NULL');
    } else {
      localStorage.clear();
      
      axios.post('https://s25-admin.allianceswisspass.dev.mxm.ch/token', querystring.stringify({
        TNC: params["?TNC"],
        grant_type: 'password'
      }))
      
      .then(response => {
        window.localStorage.setItem('AccessToken', 'Bearer ' + response.data.access_token);
        this.props.history.push('welcome')
      })
      .catch(error => {
        console.log('There is an error: ', error.message)
        if (error.message === 'Request failed with status code 400') {
          this.setState({
            dialogError: 'Diese Angaben sind ungültig, bitte überprüfen Sie PIN und/oder Nachname.',
            fade: 'in'
          })
        } else {
          this.setState({
            dialogError: '' + error.message,
            fade: 'in'
          })
        }
      });
    }
  }

  secondStepHandler = () => {
    this.setState({
      secondStep: 'secondStepLoginTrigger'
    })
  }

  languageRouter = () => {
    if (window.location.host === 'localhost:3000' && window.location.pathname === '/') {
        this.props.history.push('/de');
    }
    if (window.location.host === '725.dev.mxm.ch' && window.location.pathname === '/') {
        this.props.history.push('/de');
    }
    if (window.location.host === 'ga.allianceswisspass.ch' && window.location.pathname === '/') {
        this.props.history.push('/de');
    }
    if (window.location.host === 'ag.allianceswisspass.ch' && window.location.pathname === '/') {
        this.props.history.push('/fr');
    }
    if (window.location.host === 'agquestionario.allianceswisspass.ch' && window.location.pathname === '/') {
        this.props.history.push('/it');
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();

    axios.post('https://s25-admin.allianceswisspass.dev.mxm.ch/token', querystring.stringify({
      Pin: event.target[0].value,
      Lastname: event.target[1].value,
      grant_type: 'password'
    }))

      .then(response => {
        window.localStorage.setItem('AccessToken', 'Bearer ' + response.data.access_token);
        this.props.history.push(window.location.pathname + '/welcome')
      })
      .catch(error => {
        console.log('There is an error: ', error.message)
        if (error.message === 'Request failed with status code 400') {
          window.location.pathname === '/fr' ?
            this.setState({
              dialogError: 'Ces données ne sont pas valables, veuillez vérifier le NIP et/ou le nom, puis réessayer.',
              fade: 'in'
            })
          : window.location.pathname === '/it' ?
              this.setState({
                dialogError: 'Questi dati non sono validi; verifichi il PIN e/o il nome e riprovi.',
                fade: 'in'
              })
          : this.setState({
            dialogError: 'Diese Angaben sind ungültig. Bitte überprüfen Sie PIN und/oder Name und versuchen Sie es erneut.',
            fade: 'in'
          })
        } else {
          this.setState({
            dialogError: '' + error.message,
            fade: 'in'
          })
        }

      });
  }

  render() {
    
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" disableGutters className="loginScreen">
            <Card raised="true" className={"errorDialog fade-" + this.state.fade}>
              <CardContent className="errorContent">
                <Typography align="center">
                  {this.state.dialogError}
                </Typography>
              </CardContent>
            </Card>
            <Container maxWidth="false" disableGutters className="loginInfoText">
              <Typography className="infoText" variant="h5" align="center">
                Login
              </Typography>
              <Typography variant="h6" align="center">
                Logge dich mit einem Klick auf deinen persönlichen Teilnahmelink ein, den du per E-Mail oder SMS erhalten hast.
              </Typography>
              <Typography variant="body" className="loginNextStep" onClick={() => this.secondStepHandler() } >
                Dein Teilnahmelink funktioniert nicht?
              </Typography>
            </Container>
            <Container maxWidth="false" disableGutters className={"loginInfoText " + this.state.secondStep}>
              <Typography variant="h5" align="center">
                Dein Teilnahmelink <br /> funktioniert nicht?
              </Typography>
              <Typography variant="h6" align="center">
                Wende dich per E-Mail an uns.
              </Typography>
              <Button className="loginButton" href="mailto:contact@allianceswisspass.ch">
                Hilfe anfordern
              </Button>
            </Container>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);