import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./styles.css";
import AppBar from '../../Shared/AppBar';
import axios from 'axios';
import RegisterMain from './Main';

class Register extends Component {
    constructor(props) {
        super(props);
        window.location.pathname.includes('/fr') ?
            this.state = {
                page: 'Inscription',
                back: true,
                prev: '',
                userProfile: [],
                resultsData: []
            }
        : window.location.pathname.includes('/it') ?
            this.state = {
                page: 'Registrazione',
                back: true,
                prev: '',
                userProfile: [],
                resultsData: []
            }
        :
            this.state = {
                page: 'Registrieren',
                back: true,
                prev: '',
                userProfile: [],
                resultsData: []
            }
      }

      componentDidMount() {
        this.isUserAuthenticated();
        this.handleUser();
        this.visitsChecker();
    }

    visitsChecker = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Get'
    
        axios.get(proxyUrl + targetUrl, {
            headers: { "Authorization": window.localStorage.AccessToken }
        })
        .then((response) => {
            this.setState({ visits: response.data });
            console.log('VISITS RESPONSE: ', response.data);
            this.visitsHandler();
        })
        .catch((error) => { console.log(error);
        })
      }

      visitsHandler = () => {
        if (this.state.visits.IsActivationPageVisited === true) {
          console.log('Welcome Back');
        } else {
          var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
              targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Post?page=ActivationPage'
          
    
          var myHeaders = new Headers();
              myHeaders.append("Authorization", window.localStorage.AccessToken);
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow'
          };
    
          fetch(proxyUrl + targetUrl, requestOptions)
          .then(response => response.json())
          .then(result => console.log(result))
          .catch(error => console.log('error', error));
        }
      }
    
    isUserAuthenticated = () => {
        var auth = localStorage.AccessToken
        if (auth === undefined) {
            localStorage.clear();
            this.props.history.push('/')
        }
    }

    backButtonHandler = () => {
        if (this.state.page === 'Registrieren') {
          localStorage.clear();
          this.props.history.push('/de')
        }
        if (this.state.page === 'Inscription') {
          localStorage.clear();
          this.props.history.push('/fr')
        }
        if (this.state.page === 'Registrazione') {
          localStorage.clear();
          this.props.history.push('/it')
        }
    }

    loginHandler = () => {
        this.props.history.push('home')
    }

    handleUser = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/authentication/userInfo'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
                userProfile: result.Preload,
                resultsData: result
            });
        })
        .then(result => {
            if (this.state.resultsData.IsRegistered === true) {
                this.props.history.push('/')
            }
        })
        .catch(error => console.log('error', error));
    }

    activationTrigger = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Authentication/ActivationOpened'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));
    }

    customActivationTrigger = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Report/ReportEntry'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .catch(error => console.log('error', error));
    }

    render() {
        // console.log('REGISTER PAGE STATE: ', this.state);
        this.activationTrigger();
        this.customActivationTrigger();
        
        return(
            <React.Fragment>
                <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
                <RegisterMain mainState={this.state} loginHandler={this.loginHandler} />
            </React.Fragment>
        )
    }
}

export default withRouter(Register);