import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import AppBar from '../../Shared/AppBar';
import querystring from 'querystring';
import Cry from '../../assets/icons/crying-baby-svgrepo-com.svg';
import './styles.css';

class Main extends Component {

  constructor(props) {
    super(props);
    window.location.pathname.includes('/fr') ?
    this.state = {
      page: 'Se désabonner',
      back: true,
    }
    : window.location.pathname.includes('/it') ?
    this.state = {
      page: 'Annulla l\'iscrizione',
      back: true,
    }
    :
    this.state = {
      page: 'Unsubscribe',
      back: true,
    }
  }

  componentDidMount() {
    this.unsubscribeHandler()
  }

  backButtonHandler = () => {
    this.state.page === 'Vielen Dank' ?
      this.props.history.push('/fr')
    : this.state.page === 'Vielen Dank' ?
      this.props.history.push('/it') 
    : this.props.history.push('/de')
  }

  unsubscribeHandler = () => {
    let params = querystring.parse(this.props.location.search);
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
        targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Report/ReportUnsubscribe?TNC='


    if (params["?TNC"] === undefined) {
      // console.log('Params NULL');
    } else {
      localStorage.clear();

      var requestOptions = {
        method: 'POST',
        redirect: 'follow'
      };
      
      fetch(proxyUrl + targetUrl + params["?TNC"], requestOptions)
        .then(response => response.text())
        .then(result => console.log(result))
        .catch(error => console.log('error', error));
  }
  }

  render() {
    return (
      <React.Fragment>
        <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
        <CssBaseline />
        <Container className="finishSection" maxWidth="false">
          <div>
            <div className="checkMark">
              <img src={Cry} alt="Vielen Dank!" />
            </div>
            {
              window.location.pathname.includes('/fr') ?
                <p>Oh Noo. You have unsubscribed :( </p>
              : window.location.pathname.includes('/it') ?
                <p>Oh Noo. You have unsubscribed:( </p>
              :
                <p>Oh Noo. You have unsubscribed:( </p>
            }
          </div>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);