import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./styles.css";
import AppBar from '../../Shared/AppBar';
import WelcomeScreen from './Main';
import axios from 'axios';

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
          page: '',
          back: false,
          prev: '',
          userProfile: [],
          resultsData: [],
          path: window.location.pathname.substring(0,3),
          ip: null,
          visits: [],
          logCounter: 0
        }
      }
      
    componentDidMount() {
        this.visitsChecker();
        this.isUserAuthenticated();
        this.handleUser();
        this.handleTrips();
    }

    visitsChecker = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/PageVisit/Get'
    
        axios.get(proxyUrl + targetUrl, {
            headers: { "Authorization": window.localStorage.AccessToken }
        })
        .then((response) => {
            this.setState({ visits: response.data });
            console.log('VISITS RESPONSE: ', response.data);
            this.visitsHandler();
        })
        .catch((error) => { console.log(error);
        })
    }

    isUserAuthenticated = () => {
        var auth = localStorage.AccessToken
        if (auth === undefined) {
            localStorage.clear();
            this.props.history.push(this.state.path + '/')
        }
    }

    handleUser = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/authentication/userInfo'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
                userProfile: result.Preload,
                resultsData: result
            });
        })
        .catch(error => console.log('error', error));
    }

    logSuccesLogin = () => {
    var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Authentication/UserLog'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");

        fetch("https://geolocation-db.com/json/")
        .then(response => {
            return response.json();
        }, "jsonp")
        .then(res => {
            this.setState({ip: res.IPv4})
        })
        .catch(err => console.log(err))


        var raw = JSON.stringify(
            {   
                "UserId": this.state.userProfile.Id,
                "IpAdress": this.state.ip,
                "UserAgent": window.browserData.ua
            }
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        if (this.state.ip.includes(".")) {
            fetch(proxyUrl + targetUrl, requestOptions)
            .then(response => response.json())
            .then(result => {
                this.setState({
                    userProfile: result.Preload,
                    resultsData: result
                });
            })
            .catch(error => console.log('error', error));
        }
    }

    handleTrips = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Journeys/Journeys'

        var myHeaders = new Headers();
        myHeaders.append("Authorization", window.localStorage.AccessToken);
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            this.setState({
              tripsData: result
            });
        })
        .catch(error => console.log('error', error));
    }

    userLog = () => {
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Authentication/UserLog'
          

          var myHeaders = new Headers();
              myHeaders.append("Authorization", window.localStorage.AccessToken);
          var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow'
          };

          if (this.state.logCounter === 0) {
            fetch(proxyUrl + targetUrl, requestOptions)
            .then(response => response.json())
            .then(result => console.log(result))
            .catch(error => console.log('error', error));
            this.setState({
                logCounter: this.state.logCounter + 1
            })
            this.props.history.push('/home')   
          }
    }

    routeUser = () => {
        this.state.resultsData.IsRegistered === false ? 
            this.props.history.push(this.state.path + '/register')
        : this.state.resultsData.IsAdmin === true ?
            window.location = "https://ga-admin.allianceswisspass.ch/admin/user"
        : 
            setTimeout(() => {
                this.state.tripsData === null ?
                    this.props.history.push(this.state.path + '/landing')
                : this.userLog()
            }, 1000);
    }

    userLanguage = () => {
        if (this.state.userProfile.Language === 1 && window.location.host === 'ga.allianceswisspass.ch') {
            this.isUserLocked();
        }
        if (this.state.userProfile.Language === 2 && window.location.host === 'ga.allianceswisspass.ch') {
            localStorage.clear();
            window.location.href = 'https://ag.allianceswisspass.ch/fr?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }
        if (this.state.userProfile.Language === 3 && window.location.host === 'ga.allianceswisspass.ch') {
            localStorage.clear();
            window.location.href = 'https://agquestionario.allianceswisspass.ch/it?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }
        
        if (this.state.userProfile.Language === 2 && window.location.host === 'ag.allianceswisspass.ch') {
            this.isUserLocked();
        }
        if (this.state.userProfile.Language === 1 && window.location.host === 'ag.allianceswisspass.ch') {
            localStorage.clear();
            window.location.href = 'https://ga.allianceswisspass.ch/fr?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }
        if (this.state.userProfile.Language === 3 && window.location.host === 'ag.allianceswisspass.ch') {
            localStorage.clear();
            window.location.href = 'https://agquestionario.allianceswisspass.ch/it?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }

        if (this.state.userProfile.Language === 3 && window.location.host === 'agquestionario.allianceswisspass.ch') {
            this.isUserLocked();
        }
        if (this.state.userProfile.Language === 1 && window.location.host === 'agquestionario.allianceswisspass.ch') {
            localStorage.clear();
            window.location.href = 'https://ga.allianceswisspass.ch/fr?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }
        if (this.state.userProfile.Language === 2 && window.location.host === 'agquestionario.allianceswisspass.ch') {
            localStorage.clear();
            window.location.href = 'https://agquestionario.allianceswisspass.ch/it?login&Pin=' + this.state.userProfile.Pin + '&Lastname=' + this.state.userProfile.Name;
        }

        if (this.state.userProfile.Language === 1 && window.location.host === 'localhost:3000') {
            this.isUserLocked();
        }
        if (this.state.userProfile.Language === 2 && window.location.host === 'localhost:3000') {
            this.isUserLocked();
        }
        if (this.state.userProfile.Language === 3 && window.location.host === 'localhost:3000') {
            this.isUserLocked();
        }

        if (this.state.userProfile.Language === 1 && window.location.host === '725.dev.mxm.ch') {
            this.isUserLocked();
        }
        if (this.state.userProfile.Language === 2 && window.location.host === '725.dev.mxm.ch') {
            this.isUserLocked();
        }
        if (this.state.userProfile.Language === 3 && window.location.host === '725.dev.mxm.ch') {
            this.isUserLocked();
        }
    }

    isUserLocked = () => {
        if (this.state.userProfile.LockSource === null) {
            // console.log('LOCK SOURCE IF IS: ', this.state.userProfile.LockSource);
            setTimeout(()=>{
                this.routeUser();
            }, 2000);
        } else {
            // console.log('LOCK SOURCE ELSE IS: ', this.state.userProfile.LockSource);
            localStorage.clear();
            this.props.history.push(this.state.path + '?locked')
        }
    }

    render() {
        setTimeout(()=>{
            this.userLanguage();
            // console.log('LOCAAAAALLLLLL: ',window.browserData.ua);
            // console.log('User ID', this.state.userProfile.Id);
        }, 200);
        return (
            <React.Fragment>
                <AppBar state={this.state}/>
                <WelcomeScreen state={this.state.userProfile} path={this.state.path} />
            </React.Fragment>
        )
    }
}

export default withRouter(Main);