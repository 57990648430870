import React from 'react';
import './App.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Routes from "./Routes";

function App() {
  return (
    <div className="App-main">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Routes />
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default App;
