import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./styles.css";
import AppBar from '../../Shared/AppBar';
import NavBar from '../../Shared/NavBar';
import Main from './Main';
import "./styles.css";

class Settings extends Component {
    constructor(props) {
        super(props);
        window.location.pathname.includes('/fr') ?
        this.state = {
            page: 'Mon profil',
            back: true,
            value: 'settings',
            loading: false
          }
        : window.location.pathname.includes('/it') ?
        this.state = {
            page: 'Il mio profilo',
            back: true,
            value: 'settings',
            loading: false
          }
        :
        this.state = {
          page: 'Mein Profil',
          back: true,
          value: 'settings',
          loading: false
        }
      }

      componentDidMount() {
        this.isUserAuthenticated();
    }
    
    isUserAuthenticated = () => {
        var auth = localStorage.AccessToken
        if (auth === undefined) {
            localStorage.clear();
            this.props.history.push('/')
        }
    }

    loadingScreen = () => {
        this.setState({
            loading: true
        })
    }

    backButtonHandler = () => {
        if (this.state.page === 'Mein Konto') {
          this.props.history.push('home')
        }
    }

    render() {
        // console.log('SETTING PAGE STATE: ', this.state);
        return(
            <React.Fragment>
                <AppBar state={this.state} backButtonHandler={this.backButtonHandler} />
                <Main loadingScreen={this.loadingScreen} />
                <NavBar state={this.state} />
            </React.Fragment>
        )
    }
}

export default withRouter(Settings);