import React, { Component } from "react";
import RegisterPage from '../../components/Register';
import "./styles.css";

export default class Register extends Component {
    render() {
        return (
            <div className="Main-app">
                <RegisterPage />
            </div>
        )
    }
}