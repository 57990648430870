import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import LocomotiveIcon from '../../assets/icons/locomotive.svg';
import InformationIcon from '../../assets/icons/information.svg';
import QuestionIcon from '../../assets/icons/question.svg';
import UserIcon from '../../assets/icons/user.svg';
import StatisticsIcon from '../../assets/icons/statistic.svg';
import './styles.css';

class Main extends Component {

  handleChange = (event, newValue) => {
    this.props.history.push(newValue)
  };

  render() {
    return (
        <BottomNavigation value={this.props.state.value} onChange={this.handleChange} className="navBar">
          <BottomNavigationAction className="navButton" value="home" icon={ <img src={LocomotiveIcon} alt="Reisejournal" /> } />
          <BottomNavigationAction className="navButton" value="information" icon={ <img src={InformationIcon} alt="Infos" /> } />
          <BottomNavigationAction className="navButton" value="help" icon={ <img src={QuestionIcon} alt="Fragen und Kontakt" /> } />
          <BottomNavigationAction className="navButton" value="statistics" icon={ <img src={StatisticsIcon} alt="Statistic" /> } />
          <BottomNavigationAction className="navButton" value="settings" icon={ <img src={UserIcon} alt="Mein Konto" /> } />
        </BottomNavigation>
    )
  }
}

export default withRouter(Main);