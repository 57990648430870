import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Skeleton from '@material-ui/lab/Skeleton';
import axios from 'axios';
import "./styles.css";
import moment from "moment";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
          term: null,
          conStations: [],
          stations: [],
          display: 'hide'
        }

        this.searchHandler = this.searchHandler.bind(this);
    }

    componentDidMount() {
        this.loadConnectingStations();
        setTimeout(()=>{
            this.keyNavigation();
            this.keyEnter();
        }, 500);
    }

    keyEnter = () => {
        document.addEventListener('keydown', function(event) {
            if (event.which === 13) {
                document.getElementsByClassName('selected')[0].click();
            }
        })
    }

    keyNavigation = () => {
        var ul = document.getElementById('list');
        var liSelected;
        var index = -1;
        var next;

        document.addEventListener('keydown', function(event) {
            var len = ul.getElementsByTagName('li').length-1;
            if(event.which === 40) {
                index++;
                //down 
                if (liSelected) {
			        removeClass(liSelected, 'selected');
                    next = ul.getElementsByTagName('li')[index];
                    if(typeof next !== undefined && index <= len) {
                        liSelected = next;
                    } else {
         	            index = 0;
                        liSelected = ul.getElementsByTagName('li')[0];
                    }
                    addClass(liSelected, 'selected');
                    // console.log(index);
                }
                else {
                    index = 0;
   	                liSelected = ul.getElementsByTagName('li')[0];
			        addClass(liSelected, 'selected');
                }
            }   else if (event.which === 38) {
                //up
                if (liSelected) {
			        removeClass(liSelected, 'selected');
                    index--;
                    // console.log(index);
                    next = ul.getElementsByTagName('li')[index];
                    if(typeof next !== undefined && index >= 0) {
                        liSelected = next;
                    } else {
            		    index = len;
                        liSelected = ul.getElementsByTagName('li')[len];
                    }
                    addClass(liSelected, 'selected');
                }   else {
                        index = 0;
   	                    liSelected = ul.getElementsByTagName('li')[len];
			            addClass(liSelected, 'selected');
                }
            }
        }, false);

        function removeClass(el, className) {
            if(el.classList) {
                el.classList.remove(className);
            } else {
                el.className = el.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        };

        function addClass(el, className) {
            if(el.classList) {
                el.classList.add(className);
            } else {
                el.className += ' ' + className;
            }
        };
    }

    dynamicTextSize = () => {
        var button;
        var that;
        var textLength;

        this.state.conStations.slice(0, 8).map(station => (
            // eslint-disable-next-line
            button = document.getElementById('via' + station.code),
            that = document.getElementById('via' + station.code).innerHTML,
            // console.log('THAT: ', that),
            textLength = that.length,
            // console.log('TEXT LENGTH ARRIVALS: ', textLength),
            
            textLength > 30 ?
                button.classList.add("font5")
            : textLength > 20 ?
                button.classList.add("font10")
            : textLength > 10 ?
                button.classList.add("font14")
            : null
        ))
    }

    loadConnectingStations = () => {
        let date = new Date(this.props.state.date);
        var finalCon = [];
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Trip/GetTrips?from=' + this.props.state.startPointName + '&to=' + this.props.state.endPointName + '&date=' + moment(date).format('YYYY/MM/DD')

        var myHeaders = new Headers();
            myHeaders.append("Authorization", window.localStorage.AccessToken);

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(proxyUrl + targetUrl, requestOptions)
        .then(response => response.json())
        .then(result => {
            // console.log(result);

            for (let i = 0; i < result.length; i++) {
                result[i].Legs.map( cons => (
                    parseInt(cons.From.Code) < parseInt(cons.To.Code) ?
                        finalCon.push({'code': cons.From.Code,'name': cons.From.Name})
                    :   finalCon.push({'code': cons.To.Code,'name': cons.To.Name})
                ))
            }

            // console.log('FINALCON BEFORE IF: ', finalCon);

            const distinctCon = [];

            const distinctName = [...new Set(finalCon.map(con => con.name))];
            const distinctCode = [...new Set(finalCon.map(con => con.code))];

            for (let i = 0; i < distinctName.length; i++) {
                distinctCon.push({'code': distinctCode[i], 'name': distinctName[i]})
            }

            // console.log('DISTINCTCON BEFORE FOR LOOP: ', distinctCon);

            for (let i = 0; i < distinctCon.length; i++) {
                if (distinctCon[i].name === this.props.state.startPointName) {
                    distinctCon.splice(i, 1)
                }
                if (distinctCon[i].name === this.props.state.endPointName) {
                    distinctCon.splice(i, 1)
                }
            }

            // console.log('FINALCON AFTER IF: ', finalCon);
            // console.log('distinctName AFTER IF: ', distinctName);
            // console.log('distinctCode AFTER IF: ', distinctCode);
            // console.log('distinctCon AFTER IF: ', distinctCon);

            this.setState({
                conStations: distinctCon
            })
            this.dynamicTextSize();
        })
        .catch(error => console.log('error', error));
        
    }

    searchHandler(event){
        this.setState({
            [event.target.name]: event.target.value
        })
        var proxyUrl = 'https://crocs-ga20.herokuapp.com/',
            targetUrl = 'https://s25-admin.allianceswisspass.dev.mxm.ch/api/Station/GetStations'

        axios.get(proxyUrl + targetUrl, {
            headers: { "Authorization": window.localStorage.AccessToken },
            params: { query: ' ' + event.target.value}
        })
        .then((response) => {
            this.setState({ stations: response.data });
        })
        .catch((error) => { console.log(error);
        })
    }

    handleInputFocus = () => {
        this.setState({
            display: 'block'
        })
    }

    handleInputBlur = () => {
        setTimeout(()=>{
            this.setState({
                display: 'hide'
            })
        }, 500)
    }

  render() {
    // console.log('PROPS Connecting: ', this.props.state);
    // console.log('STATE Connecting: ', this.state);
    
    return (
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="false" className="StartortScreen" disableGutters="true">
            <Container maxWidth="false" disableGutters className="homeBanner">
                <Typography variant="h6" className="homeBannerText">
                    {window.location.pathname.includes('/fr') ?  'Veuillez indiquer le lieu de correspondance' : window.location.pathname.includes('/it') ? 'Registri il punto di cambio.' : 'Bitte erfasse'}
                </Typography>
                <Typography variant="h6" className="homeBannerText">
                    {window.location.pathname.includes('/fr') ?  'Veuillez indiquer le lieu de correspondance' : window.location.pathname.includes('/it') ? 'Registri il punto di cambio.' : 'den Umsteigeort.'}
                </Typography>
            </Container>
            <Container maxWidth="false" disableGutters>
                <TextField onFocus={this.handleInputFocus} onBlur={this.handleInputBlur} autoComplete='off' id="von" label="Via" fullWidth className="searchField" onChange={this.searchHandler} />
                <ul id="list" className="searchResults">
                    {
                        this.state.stations.slice(0, 5).map( stations => (
                            <li onClick={() => this.props.handleConnectingPoint(stations.Name, stations.Code)}>{stations.Name}</li>
                        ))
                    }
                </ul>
                <Typography variant="body2" className="favouriteCopy" >{window.location.pathname.includes('/fr') ?  'Propositions / Favoris' : window.location.pathname.includes('/it') ? 'Proposte / Preferiti' : 'Vorschläge / Favoriten'}</Typography>
                <div className="connSuggestionsButton">
                {
                    this.state.conStations === null ?
                        null
                    : this.state.conStations.length > 0 ?
                        this.state.conStations.slice(0, 8).map(station => (
                            <Button onClick={() => this.props.handleConnectingPoint(station.name, station.code)} variant="contained" className="stationButton" disableElevation size="large">
                                <span id={"via" + station.code}>{station.name}</span>
                            </Button>
                        ))
                    : 
                        <Skeleton animation="wave" height={42} className="stationSkeleton" />
                }
                </div>
            </Container>
        </Container>
      </React.Fragment>
    )
  }
}

export default withRouter(Main);